<script setup>
import UserList from '@/components/utilisateurs/UserList.vue'
import InscriptionForm from '@/components/connexion-inscription/InscriptionForm.vue';
import AddGroup from '@/components/groupes/AddGroup.vue';
import GroupList from '@/components/groupes/GroupList.vue';
import AddContact from '@/components/interlocuteurs/AddContact.vue';
import ContactList from '@/components/interlocuteurs/ContactList.vue';
</script>

<template>
  <div class="row mb-4">
    <div class="col-lg-7">
      <UserList/>
    </div>
    <div class="col-lg-5">
      <InscriptionForm/>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-lg-7">
      <GroupList/>
    </div>
    <div class="col-lg-5">
      <AddGroup/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7">
      <ContactList/>
    </div>
    <div class="col-lg-5">
      <AddContact/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

