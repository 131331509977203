<template>
  
  <div class="modifsForm text-start mb-3 white-bg">
    <form class="modifsFormContent" @submit.prevent="submitForm">
      <div class="body">
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-6">
              <label for="InputNom" class="form-label">{{ $t('account_client.form.name_label') }}</label>
              <input type="text" v-model="modifs.nom" :placeholder="$t('account_client.form.name_label')" class="form-control" id="InputNom">
            </div>
            <div class="col-lg-6">
              <label for="InputPrenom" class="form-label">{{ $t('account_client.form.surname_label') }}</label>
              <input type="text" v-model="modifs.prenom" :placeholder="$t('account_client.form.surname_label')" class="form-control" id="InputPrenom">
            </div>
          </div>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputEmail" class="form-label">{{ $t('account_client.form.email_label') }}</label>
              <input type="email" v-model="modifs.email" :placeholder="$t('account_client.form.email_label')" class="form-control" id="InputEmail">
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputTelephone" class="form-label">{{ $t('account_client.form.phone_label') }}</label>
              <input type="tel" v-model="modifs.telephone" :placeholder="$t('account_client.form.phone_label')" class="form-control" id="InputTelephone">
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputAdresse" class="form-label">{{ $t('account_client.form.address_label') }}</label>
              <input type="text" v-model="modifs.adresse" :placeholder="$t('account_client.form.address_label')" class="form-control" id="InputAdresse">
            </div>
          </div>
        </div>

        <div class="mb-3">
          <router-link class="changeMdp" to="/compte-client/modifier-mot-de-passe">
            <i class="bi bi-key-fill"></i>
            <span>{{ $t('account_client.form.password_link') }}</span>
          </router-link>
        </div>

        <button type="submit" class="btn btn-primary">{{ $t('account_client.form.modify_button') }}</button>
      </div>
    </form>
  </div>

  <div class="languageForm white-bg mb-3">
    <label for="InputLanguage" class="form-label">{{ $t('account_client.form.default_language_label') }}</label>
    <select class="form-control" v-model="modifs.language" @change="submitForm">
      <option value="fr">{{ $t('menu.french') }}</option>
      <option value="en">{{ $t('menu.english') }}</option>
    </select>
  </div>

  <div class="rgpdForm white-bg mb-3">
    <label for="InputRGPD" class="form-label">{{ $t('account_client.form.rgpd_label') }}</label>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model="modifs.rgpd" id="InputRGPD" @change="submitForm" :checked="modifs.rgpd == 1">
      <label class="form-check-label" for="InputRGPD">
        {{ $t('account_client.form.rgpd_content_button') }}
      </label>
    </div>
  </div>

</template>

<script>
import { useUsersStore } from '@/store/users.store';
import Swal from 'sweetalert2';
import { useAuthStore } from '@/store/auth.store';

export default {
  data() {
      return {
          modifs: {
              id: '',
              nom:  '',
              prenom: '',
              role: '',
              groupe: '',
              email: '',
              telephone: '',
              adresse: '',
              mot_de_passe: '',
              interlocuteur_nom: '',
              interlocuteur_prenom: '',
              interlocuteur_id: '',
              language: '',
              rgpd: 0,
          },
          message: '',
      };
  },

  computed: {
      user() {
          const authStore = useAuthStore();
          return authStore.user;
      },
  },
  mounted() {
      // Pré-remplir les champs avec les infos de l'utilisateur
      this.modifs = {
          id: this.user.id,
          nom: this.user.nom,
          prenom: this.user.prenom,
          role: this.user.role,
          groupe: this.user.groupe,
          email: this.user.email,
          telephone: this.user.telephone,
          adresse: this.user.adresse,
          mot_de_passe: this.user.mot_de_passe,
          interlocuteur_nom: this.user.interlocuteur_nom,
          interlocuteur_prenom: this.user.interlocuteur_prenom,
          interlocuteur_id: this.user.interlocuteur_id,
          language: this.user.language,
          rgpd: this.user.rgpd,
      };
  },
  methods: {
      submitForm() {
        const usersStore = useUsersStore();

        // Conversion manuelle du boolean en 0 ou 1 avant l'envoi
        const modifsWithBooleanAsInt = {
            ...this.modifs,
            rgpd: this.modifs.rgpd ? 1 : 0, // Convertir true/false en 1/0
        };

        usersStore.updateUser(modifsWithBooleanAsInt);
      },
      showAlertPositive() {
          const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
              }
          });
          Toast.fire({
              icon: "success",
              title: this.message
          });
      },
      showAlertNegative() {
          const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
              }
          });
          Toast.fire({
              icon: "error",
              title: this.message
          });
      }
  }
}
</script>

<style lang="scss" scoped>
  $five: #2F3937;
  .modifsForm {
      width: 55%;
      margin: auto;
      margin-top: 35px;
      @media screen and (max-width:992px) {
          width: 100%;
      }
  }
  .languageForm, .rgpdForm {
    width: 55%;
    margin: auto;
    label {
      display: block;
      text-align: left;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  a.changeMdp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 25px;
      text-decoration: none;
      color: $five;
      span {
          text-decoration: underline;
      }
  }
</style>