<template>
    <div class="userList dernieresConfigs text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('configurations.last_configurations_list') }}</h2>
            <router-link class="btn-secondary" to="/offres-client"><i class="bi bi-list-stars"></i>{{ $t('configurations.full_list') }}</router-link>
        </div>
        <div class="body">
            <table class="table" v-if="configurationStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 20%;" scope="col">{{ $t('configurations.project_name') }}</th>
                        <th scope="col">{{ $t('configurations.location') }}</th>
                        <th scope="col">{{ $t('configurations.date') }}</th>
                        <th scope="col">{{ $t('configurations.nominal_biogas_flow') }}</th>
                        <th scope="col">{{ $t('configurations.max_biogas_flow') }}</th>
                        <th scope="col">{{ $t('configurations.evolution_biogas_flow') }}</th>
                        <th scope="col">{{ $t('configurations.boiler_power') }}</th>
                        <th style="width: 20%;" scope="col">{{ $t('configurations.selection') }}</th>
                        <th style="width: 20%;" scope="col">{{ $t('configurations.total_price') }}</th>
                        <th scope="col">{{ $t('configurations.download') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="configuration in configurationStore" :key="configuration.id">
                        <td>{{ configuration.nom_projet }}</td>
                        <td>{{ configuration.lieu_projet }}</td>
                        <td>{{ formatDate(configuration.date_creation) }}</td>
                        <td>{{ configuration.valeur_debit_brut }} Nm³/h</td>
                        <td>{{ configuration.deb_hum_max }} Nm³/h</td>
                        <td>{{ configuration.deb_hum_max_evo }} Nm³/h</td>
                        <td v-if="configuration.puissance_chaudiere">
                            {{ configuration.puissance_chaudiere }} kW
                        </td>
                        <td v-else>
                            {{ $t('configurations.not_available') }}
                        </td>
                        <td>{{ configuration.nom_commercial }}</td>
                        <td>{{ formatNumber(configuration.total) }} €</td>
                        <td @click="generatePdf(configuration.configuration_id)">
                            <button class="download">{{ $t('configurations.download_pdf') }}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('configurations.no_configuration') }} &#128533;</div>
        </div>
    </div>
</template>
<script>

import { useConfigurationsStore } from '@/store/configurations.store';
import axios from '@/axiosConfig.js';


export default {
    data() {
        return {
            users: [],
            message: '',
            userEditing: null,
            searchQuery: '',
            groups: [],
            contacts: [],
        };
    },
    computed: {
        configurationStore() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.configurations;
        },
     
        hasMoreConfigurations() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.hasMoreConfigurations;
        }
    },
    mounted() {
        if (this.configurationStore.length === 0) {
            this.loadConfigurations(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {
 
        loadConfigurations() {
            const configurationsStore = useConfigurationsStore();
            configurationsStore.loadConfigurations();
        },
        handleSearch() {
            const configurationsStore = useConfigurationsStore();
            configurationsStore.params.query = this.searchQuery;
            configurationsStore.loadConfigurations(true); // Reset and load offers based on the search query
        },
        generatePdf(id) {
    
            const email = "empty";

            axios.get(`https://arolenergy-05673c117bbd.herokuapp.com/configurations/pdf/${id}/${email}`, {
               
            })
            .then(response => {
                this.configuration = response.data[0]; // Assigne le premier élément de la réponse à `this.configuration`
                const pdfUrl = `https://arolenergy-05673c117bbd.herokuapp.com/chiffrages/${response.data.url}`;
                this.downloadFile(pdfUrl);
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
            });
        },
        downloadFile(pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'configuration.pdf'); // Ajoute l'attribut download avec un nom de fichier
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        formatNumber(num) {
            if (num === null || num === undefined || isNaN(num)) {
                return "N/A";  // Return a placeholder like "N/A" when the value is null, undefined, or NaN
            }
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

    },
    
    beforeUnmount() {
        const configurationsStore = useConfigurationsStore();
        configurationsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    .header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width:1499px) {
            flex-wrap: wrap;
        }
    }


    
</style>