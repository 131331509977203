<template>
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>{{ $t('add_option_form.title') }}</h2>
        </div>
        <div class="body">
            <div class="mb-3">
                <label for="InputNom" class="form-label">{{ $t('add_option_form.labels.name') }}</label>
                <input type="text" v-model="option.nom" :placeholder="$t('add_option_form.placeholders.name')" class="form-control" id="InputNom" required>
            </div>
            <div class="mb-3">
                <label for="SelectInterlocuteur" class="form-label">{{ $t('add_option_form.labels.category') }}</label>
                <select class="form-select" id="SelectInterlocuteur" required v-model="option.categorie">
                    <option value="">{{ $t('add_option_form.placeholders.select_category') }}</option>
                    <option v-for="option in options" :key="option.id" :value="option.id">{{ option.nom }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="InputPrixFixe" class="form-label">{{ $t('add_option_form.labels.fixed_price') }}</label>
                <input type="number" v-model="option.prix_fixe" :placeholder="$t('add_option_form.placeholders.fixed_price')" class="form-control" id="InputPrixFixe" required>
            </div>
            <div class="mb-3">
                <label for="InputPrixMl" class="form-label">{{ $t('add_option_form.labels.linear_meter_price') }}</label>
                <input type="number" v-model="option.prix_ml" :placeholder="$t('add_option_form.placeholders.linear_meter_price')" class="form-control" id="InputPrixMl">
            </div>
         
            <button type="submit" class="btn btn-primary">{{ $t('add_option_form.buttons.submit') }}</button>
        </div>
    </form>
</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useOptionsStore } from '@/store/options.store';

export default {
    data() {
        return {
            option: {
                nom: '',
                prix_fixe: '',
                prix_ml: '',
                categorie: '',
            },
            message: '',
            options: [],
        };
    },
    mounted() {
        this.loadOptions(); 
    },
    methods: {
        submitForm() {  // Ensure a closing parenthesis here
    
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/options', this.option, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedOption = {
                        ...this.option,
                        id: response.data.id  // Ajoutez l'`id` à l'objet user
                    };
                    const optionsStore = useOptionsStore();
                    optionsStore.addOption(addedOption);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.option.nom = '';
                    this.option.prix_fixe = '';
                    this.option.prix_ml = '';
                });
        },
        loadOptions() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/options/categories', {
               
            })
            .then(response => {
                const options = response.data;
                this.options = [...options]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>