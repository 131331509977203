<template>
    <div class="modifsForm text-start mb-3 white-bg">
        <form class="modifsFormContent" @submit.prevent="submitForm">
            <div class="body">
                <div class="mb-3">
                    <label for="InputMdp" class="form-label">{{ $t('account_client_password_update.form.new_password_label') }}</label>
                    <input type="password" v-model="modifs.mot_de_passe" @input="validatePasswordStrength" :placeholder="$t('account_client_password_update.form.new_password_placeholder')" class="form-control" id="InputMdp" required>
                    <p v-if="passwordStrengthMessage" class="danger" role="alert">{{ $t('account_client_password_update.form.password_strength_message') }}</p>
                </div>

                <div class="mb-3">
                    <label for="InputConfirmMdp" class="form-label">{{ $t('account_client_password_update.form.confirm_password_label') }}</label>
                    <input type="password" v-model="modifs.confirm_mot_de_passe" :placeholder="$t('account_client_password_update.form.confirm_password_placeholder')" class="form-control" id="InputConfirmMdp" required>
                    <p v-if="passwordMismatchMessage" class="danger" role="alert">{{ $t('account_client_password_update.form.password_mismatch_message') }}</p>
                </div>

                <div class="mb-3">
                    <router-link class="changeMdp" to="/compte-client"><i class="bi bi-key-fill"></i><span>{{ $t('account_client_password_update.form.back_to_account') }}</span></router-link>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="!isFormValid">{{ $t('account_client_password_update.form.update_button') }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useAuthStore } from '@/store/auth.store';
import axios from '@/axiosConfig.js';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            modifs: {
                id: '',
                mot_de_passe: '',
                confirm_mot_de_passe: ''
            },
            message: '',
            passwordStrengthMessage: '',
            passwordMismatchMessage: ''
        };
    },
    computed: {
        isFormValid() {
            return (
                this.passwordStrengthMessage === '' &&
                this.passwordMismatchMessage === '' &&
                this.modifs.mot_de_passe !== '' &&
                this.modifs.confirm_mot_de_passe !== ''
            );
        },
        user() {
            const authStore = useAuthStore();
            return authStore.user;
        },
    },
    mounted() {
        // Pré-remplir les champs avec les infos de l'utilisateur
        this.modifs = {
            id: this.user.id,
            mot_de_passe: '',
            confirm_mot_de_passe: ''
        };
    },
    methods: {
        validatePasswordStrength() {
            const password = this.modifs.mot_de_passe;
            if (password === '') {
                this.passwordStrengthMessage = ''; // Pas de message si le champ est vide
                return;
            }

            const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

            if (!strongPasswordRegex.test(password)) {
                this.passwordStrengthMessage = "Le mot de passe doit contenir au moins 8 caractères, incluant une majuscule, une minuscule, un chiffre, et un symbole.";
            } else {
                this.passwordStrengthMessage = '';
            }

            // Vérifier la correspondance des mots de passe après avoir validé la force du mot de passe
            this.validatePasswordMatch();
        },
        validatePasswordMatch() {
            const password = this.modifs.mot_de_passe;
            const confirmPassword = this.modifs.confirm_mot_de_passe;

            if (confirmPassword === '') {
                this.passwordMismatchMessage = ''; // Pas de message si le champ est vide
                return;
            }

            if (password !== '' && password !== confirmPassword) {
                this.passwordMismatchMessage = "Les mots de passe ne correspondent pas.";
            } else {
                this.passwordMismatchMessage = ''; // Message effacé si les mots de passe correspondent
            }
        },
        submitForm() {
            if (this.isFormValid) {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/users/update-password/${this.modifs.id}`, {
                    mot_de_passe: this.modifs.mot_de_passe
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response?.data?.message || error.message);
                    this.message = error.response?.data?.message || "Une erreur s'est produite";
                    this.showAlertNegative();
                });
            }
        },
        showAlertPositive() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: this.message
            });
        }
    },
    watch: {
        'modifs.confirm_mot_de_passe': function() {
            this.validatePasswordMatch(); // Valider la correspondance dès que le champ de confirmation change
        },
        'modifs.mot_de_passe': function() {
            this.validatePasswordStrength(); // Valider la force et la correspondance dès que le champ mot de passe change
        }
    }
}
</script>

<style lang="scss" scoped>
    $five: #2F3937;
    .modifsForm {
        width: 55%;
        margin: auto;
        margin-top: 35px;
        @media screen and (max-width:992px) {
            width: 100%;
        }
    }
    a.changeMdp {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 25px;
        text-decoration: none;
        color: $five;
        span {
            text-decoration: underline;
        }
    }
    .danger, .error-message {
        color: #ec6464 !important;
        font-size: 0.9rem !important;
        margin-top: 10px;
    }
</style>