<template>
    <div class="productList text-start mb-3 white-bg">
        <div class="header">
            <h2>Variations du produit {{ $route.params.equip }}</h2>    
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" placeholder="Rechercher une variation..." @input="handleSearch" />
            </div>        
        </div>
        <div class="body">
            
            <table class="table" v-if="variationStore.length > 0">
                <thead>
                    <tr>
                        <th style="min-width: 180px;" scope="col">Id</th>
                        <th style="min-width: 180px;" scope="col">Débit max biogaz brut membrane</th>
                        <th style="min-width: 140px;" scope="col">Prix equipement</th>
                        <th style="min-width: 140px;" scope="col">Commentaires</th>
                        <!-- Add more columns as needed -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="variation in variationStore" :key="variation.id">
                        <td>
                            <div>{{ variation.id }}</div>
                        </td>
                        <td>
                            <template v-if="variationEditing === variation.id">
                                <input v-model="variation.debit_max_biogaz_brut_membrane" @keyup.enter="updateVariation">
                            </template>
                            <template v-else>
                                {{ variation.debit_max_biogaz_brut_membrane }} Nm³/h
                            </template>
                        </td>
                        <td>
                            <template v-if="variationEditing === variation.id">
                                <input type="number" v-model="variation.prix_equipement" @keyup.enter="updateVariation">
                            </template>
                            <template v-else>
                                <span v-if="variation.prix_equipement">{{ formatNumber(variation.prix_equipement) }} €</span>
                                <span v-else>Nous consulter</span>

                            </template>
                        </td>
                        <td>
                            <template v-if="variationEditing === variation.id">
                                <textarea type="text" v-model="variation.commentaires" @keyup.enter="updateVariation" rows="4"></textarea>
                            </template>
                            <template v-else>
                                {{ variation.commentaires }}
                            </template>
                        </td>
                        <td style="width: 1.5%;"><i v-if="variationEditing === variation.id" @click="updateVariation" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(variation.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteVariation(variation.id)" class="bi bi-trash3-fill"></i></td>
                        <!-- Display more product data as needed -->
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>Aucun produit disponible &#128533;</div>
            <button class="btn btn-primary" @click="loadVariations" v-if="hasMoreVariations">Afficher plus</button>
        </div>
    </div>
</template>

<script>

import { useVariationsStore } from '@/store/variations.store';

export default {
    data() {
        return {
            variations: [],
            message: '',
            variationEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        variationStore() {
            const variationsStore = useVariationsStore();
            return variationsStore.variations;
        },
     
        hasMoreVariations() {
            const variationsStore = useVariationsStore();
            return variationsStore.hasMoreVariations;
        }
    },
    mounted() {
        const variationsStore = useVariationsStore();

        const equip = this.$route.params.equip;
        variationsStore.setProduit(equip);
        this.loadVariations(true); // Charger les utilisateurs seulement s'il n'y en a pas déjà
    
    },
    methods: {
        updateVariation() {

            const editedVariation = this.variationStore.find(variation => variation.id === this.variationEditing);
            if (!editedVariation) {
                console.error("Variation non trouvée");
                return;
            }            

            const variationsStore = useVariationsStore();
            variationsStore.updateVariation(editedVariation);

            this.variationEditing = null; // Fin de l'édition
            
        },   
        loadVariations() {
            const variationsStore = useVariationsStore();
            variationsStore.loadVariations();
        },
        
        startEditing(variationId) {
            this.variationEditing = variationId; // Commencer l'édition de l'utilisateur
        },

        deleteVariation(variationDeleting) {

            const deletedVariation = this.variationStore.find(variation => variation.id === variationDeleting);

            const variationsStore = useVariationsStore();
            variationsStore.deleteVariation(deletedVariation);

        },
        handleSearch() {
            const variationsStore = useVariationsStore();
            variationsStore.params.query = this.searchQuery;
            variationsStore.loadVariations(true); // Reset and load offers based on the search query
        },
        formatNumber(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    },
    beforeUnmount() {
        const variationsStore = useVariationsStore();
        variationsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    .productList .body {
        overflow-x: scroll;
    }
</style>