import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';

export const useContactsStore = defineStore("contacts", {
    state() {
        return {
            contacts: [],
            originalContacts: [],
            params: {
                limit: 6,
                offset: 0,
                query: ''
            },
            hasMoreContacts: true,
            message: ''
        }
    },
    actions: {
        addContact(newContact) {
            this.contacts.unshift(newContact);
            this.loadContacts(true);
            console.log(this.hasMoreContacts);
        },
        async loadContacts(reset = false) {  // Ensure a closing parenthesis here
    
              // Réinitialiser la liste si nécessaire
              if (reset) {
                this.contacts = [];
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/contacts', {
               
            })
            .then(response => {
                const contacts = response.data;
                this.originalContacts = [...contacts]; // Assigne tous les utilisateurs récupérés à `this.users`
            
                // Met à jour `this.hasMoreUsers` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (contacts.length > this.params.limit) {
                    this.hasMoreContacts = true;
                } else {
                    this.hasMoreContacts = false;
                }
                console.log(this.hasMoreContacts)
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreContacts = false; // Assume no more users if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/contacts', {
                params: this.params,  
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newContacts = response.data;
                    this.contacts.push(...newContacts);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreContacts = false;
                    }
                } else {
                    this.hasMoreContacts = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreContacts = false;  // Assume no more users if there's an error
            });

        },
        async updateContact(contactData) {
    
            try {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/contacts/${contactData.id}`, contactData, {
                    
                })
                .then(response => {
                    console.log(response.data);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.error = error.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour du contact :', error);
                throw error;
            }
        },
        async deleteContact(deletedContact) {
    
            Swal.fire({
                title: "Êtes vous sûr ?",
                text: "Ce choix est irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, le supprimer !",
                cancelButtonText: "Non, j'ai changé d'avis",
            }).then((result) => {
                if (result.isConfirmed) {

                    try {
                        axios.delete(`https://arolenergy-05673c117bbd.herokuapp.com/contacts/${deletedContact.id}`, {
                            
                        })
                        .then(response => {
                            console.log(response.data.message);
                            this.message = response.data.message;
                            // Créé un nouveau tableau qui ne contient pas l'élément
                            this.contacts = this.contacts.filter(contact => contact.id !== deletedContact.id);
                            this.loadContacts(true);
                        })
                        .catch(error => {
                            console.log(error);
                            this.message = error.data.message;
                            this.showAlertPositive();
                        });
                        
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour du contact :', error);
                        throw error;
                    }


                    


                    Swal.fire({
                    title: "Contact supprimé !",
                    text: "Le contact a été correctement supprimé.",
                    icon: "success"
                    });
                }
            });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.contacts = [];
            this.originalContacts = [];
            this.params = { limit: 6, offset: 0, query: '' };
            this.hasMoreContacts = true;
            this.message = '';
        },
    },
})