import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';


export const useOptionsStore = defineStore("options", {
    state() {
        return {
            options: [],
            originalOptions: [],
            params: {
                limit: 6,
                offset: 0,
                query: ''
            },
            hasMoreOptions: true,
            message: ''
        }
    },
    actions: {
        addOption(newOption) {
            this.options.unshift(newOption);
            this.loadOptions(true);
            console.log(this.hasMoreOptions);
        },
        async loadOptions(reset = false) {  // Ensure a closing parenthesis here
    
            // Réinitialiser la liste si nécessaire
            if (reset) {
                this.options = [];
                this.params.offset = 0;
            }
            if (this.params.query) {
                this.options = [];
                this.params.limit = 6;
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/options', {
               
            })
            .then(response => {
                const options = response.data;
                this.originalOptions = [...options]; // Assigne tous les utilisateurs récupérés à `this.chaudieres`
            
                // Met à jour `this.hasMoreChaudieres` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (options.length > this.params.limit) {
                    this.originalOptions = true;
                } else {
                    this.originalOptions = false;
                }
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreOptions = false; // Assume no more chaudieres if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/options', {
                params: this.params,  
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newOptions = response.data;
                    this.options.push(...newOptions);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreOptions = false;
                    }
                } else {
                    this.hasMoreOptions = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreOptions = false;  // Assume no more chaudieres if there's an error
            });
        },

        async updateOption(optionData) {
    
            try {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/options/${optionData.id}`, optionData, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.message = error.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
                throw error;
            }
        },
        async deleteOption(deletedOption) {
    
            Swal.fire({
                title: "Êtes vous sûr ?",
                text: "Ce choix est irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, le supprimer !",
                cancelButtonText: "Non, j'ai changé d'avis",
            }).then((result) => {
                if (result.isConfirmed) {

                    try {
                        axios.delete(`https://arolenergy-05673c117bbd.herokuapp.com/options/${deletedOption.id}`, {
                            
                        })
                        .then(response => {
                            console.log(response.data.message);
                            this.message = response.data.message;
                            // Créé un nouveau tableau qui ne contient pas l'élément
                            this.options = this.options.filter(option => option.id !== deletedOption.id);
                            this.loadOptions(true);
                        })
                        .catch(error => {
                            console.log(error);
                            this.message = error.data.message;
                            this.showAlertPositive();
                        });
                        
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour du produit :', error);
                        throw error;
                    }


                    Swal.fire({
                    title: "Option supprimée !",
                    text: "L'option a été correctement supprimée.",
                    icon: "success"
                    });
                }
            });
        },

        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.options = [];
            this.originalOptions = [];
            this.params = { limit: 6, offset: 0, query: '' };
            this.hasMoreOptions = true;
            this.message = '';
        },
    },
})