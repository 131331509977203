<template>
    <div class="userList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('last_users_registered.recent_users') }}</h2>
            <router-link class="btn-secondary" to="/utilisateurs-groupes">
                <i class="bi bi-people-fill"></i>{{ $t('last_users_registered.full_user_list') }}
            </router-link>
        </div>
        <div class="body">
            <table class="table" v-if="users.length > 0">
                <thead>
                    <tr>
                        <th style="width: 10%;" scope="col">{{ $t('last_users_registered.last_name') }}</th>
                        <th style="width: 10%;" scope="col">{{ $t('last_users_registered.first_name') }}</th>
                        <th style="width: 50%;" scope="col">{{ $t('last_users_registered.email') }}</th>
                        <th style="width: 10%;" scope="col">{{ $t('last_users_registered.role') }}</th>
                        <th style="width: 10%;" scope="col">{{ $t('last_users_registered.group') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.nom }}</td>
                        <td>{{ user.prenom }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.role }}</td>
                        <td>{{ user.groupe }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import axios from '@/axiosConfig.js';



export default {
    data() {
        return {

            users: [],
            message: '',
            params: {
                limit: 6,
                offset: 0,
            },
        };
    },

    mounted() {
        this.getUsers(); // Charger les utilisateurs seulement s'il n'y en a pas déjà

    },
    methods: {
        
        async getUsers() {  // Ensure a closing parenthesis here
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/users', {
                params: this.params,  
               
            }) 
            .then(response => {
                const newUsers = response.data;
                this.users.push(...newUsers);
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
            });
        },
        
    }
}
</script>

<style lang="scss" scoped>
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
    }
</style>