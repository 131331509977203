<script setup>
import OfferListValidatedVue from '@/components/offres/OfferListValidated.vue'
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <OfferListValidatedVue/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

