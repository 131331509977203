import { createRouter, createWebHistory } from "vue-router";
import Swal from "sweetalert2";
import HomeAdminView from "@/views/admin/HomeAdminView.vue";
import HomeClientView from "@/views/client/HomeClientView.vue";
import ConnexionView from "@/views/ConnexionView.vue";
import UsersGroupView from "@/views/admin/UsersGroupView.vue";
import ProductsEquipementsView from "@/views/admin/ProductsEquipementsView.vue";
import ProductsEquipementsDetailsView from "@/views/admin/ProductsEquipementsDetailsView.vue";
import UsersOffersView from "@/views/admin/UsersOffersView.vue";
import UsersOffersValidatedView from "@/views/admin/UsersOffersValidatedView.vue";
import VariablesConfiguration from "@/views/admin/VariablesConfiguration.vue";
import { useAuthStore } from "@/store/auth.store";
import Config1View from "@/views/client/Config1View.vue";
import OffersClientView from "@/views/client/OffersClientView.vue";
import OffersClientValidatedView from "@/views/client/OffersClientValidatedView.vue";
import AsksClientView from "@/views/client/AsksClientView.vue";
import AccountClientView from "@/views/client/AccountClientView.vue";
import AccountClientPasswordView from "@/views/client/AccountClientPasswordView.vue";

const routes = [
  /* Routes communes */
  {
    path: "/connexion",
    name: "connexion",
    component: ConnexionView,
  },

  /* Routes administrateur */
  {
    path: "/tableau-de-bord-administrateur",
    name: "tableau-de-bord-administrateur",
    component: HomeAdminView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/utilisateurs-groupes",
    name: "utilisateurs-groupes",
    component: UsersGroupView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/produits-equipements",
    name: "produits-equipements",
    component: ProductsEquipementsView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/produits-equipements/:equip",
    name: "produits-equipements-detail",
    component: ProductsEquipementsDetailsView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/offres-utilisateurs",
    name: "offres-utilisateurs",
    component: UsersOffersView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/offres-utilisateurs-validees",
    name: "offres-utilisateurs-validees",
    component: UsersOffersValidatedView,
    meta: { roles: ["admin"], requiresAuth: true },
  },
  {
    path: "/variables-options",
    name: "variables-options",
    component: VariablesConfiguration,
    meta: { roles: ["admin"], requiresAuth: true },
  },

  /* Routes client */
  {
    path: "/tableau-de-bord-client",
    name: "tableau-de-bord-client",
    component: HomeClientView,
    meta: { requiresAuth: true },
  },
  {
    path: "/configurateur",
    name: "configurateur",
    component: Config1View,
    meta: { requiresAuth: true },
  },
  {
    path: "/offres-client",
    name: "offres-client",
    component: OffersClientView,
    meta: { requiresAuth: true },
  },
  {
    path: "/offres-client-validees",
    name: "offres-client-validees",
    component: OffersClientValidatedView,
    meta: { requiresAuth: true },
  },
  {
    path: "/autres-demandes",
    name: "autres-demandes",
    component: AsksClientView,
    meta: { requiresAuth: true },
  },
  {
    path: "/compte-client",
    name: "compte-client",
    component: AccountClientView,
    meta: { requiresAuth: true },
  },
  {
    path: "/compte-client/modifier-mot-de-passe",
    name: "compte-client-modifier-mot-de-passe",
    component: AccountClientPasswordView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory("/app/"),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Vérification de l'état de connexion
  await authStore.checkInitialLogin();
  const isLoggedIn = authStore.isLoggedIn;
  const userRole = authStore.user.role;

  if (isLoggedIn) {
    const isFirstTime = authStore.user.first_time_connection;
    if (!isFirstTime) {
      const { value: rgpdAccepted } = await Swal.fire({
        title: "Acceptez-vous les conditions RGPD ?",
        html: `En cochant cette case, vous acceptez que les informations demandées soient collectées afin de recevoir des communications liées à vos demandes (devis, configurations, formulaire de contact). <strong>Vous devez accepter ou refuser pour continuer</strong>. Vous pouvez <strong>changer d'avis à tout moment</strong> en vous rendant sur votre profil en haut à droite de l'écran et en cochant/décochant la case en lien avec les RGPD.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Accepter",
        cancelButtonText: "Refuser",
        reverseButtons: true,
        allowOutsideClick: false,
      });

      // Mise à jour en base selon la réponse
      await authStore.updateFirstTimeConnection(rgpdAccepted);
    }
  }

  // Redirection si l'utilisateur est connecté et tente d'accéder à la page de connexion
  if (isLoggedIn && to.path === "/connexion") {
    next(
      userRole === "admin"
        ? "/tableau-de-bord-administrateur"
        : "/tableau-de-bord-client"
    );
  }
  // Gestion des routes protégées par rôle
  else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next("/connexion");
    } else if (
      to.matched.some((record) => record.meta.roles) &&
      !to.meta.roles.includes(userRole)
    ) {
      next(
        userRole === "admin"
          ? "/tableau-de-bord-administrateur"
          : "/tableau-de-bord-client"
      );
    } else {
      next();
    }
  }
  // Redirection depuis la route racine '/'
  else if (isLoggedIn && to.path === "/") {
    next(
      userRole === "admin"
        ? "/tableau-de-bord-administrateur"
        : "/tableau-de-bord-client"
    );
  }
  // Redirection des utilisateurs non connectés essayant d'accéder à '/'
  else if (!isLoggedIn && to.path === "/") {
    next("/connexion");
  }
  // Continuer vers la route demandée
  else {
    next();
  }
});

export default router;
