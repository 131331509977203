<template>
    <div class="userList text-start mb-3 white-bg">
        <div class="header">
            <div class="titre">
                <h2>{{ $t('offer_list.title') }}</h2>
                <router-link class="btn-secondary" to="/offres-utilisateurs-validees">
                    <i class="bi bi-list-stars"></i> {{ $t('offer_list.validated_offers_button') }}
                </router-link>
            </div>  
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('offer_list.filters.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            <table class="table" v-if="configurationStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 20%;" scope="col">{{ $t('configurations_all.company') }}</th>
                        <th style="width: 20%;" scope="col">{{ $t('configurations_all.name') }}</th>
                        <th style="width: 10%;" scope="col">{{ $t('offer_list.table.headers.project_name') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.location') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.date') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.nominal_biogas_flow') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.max_biogas_flow') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.biogas_flow_evolution') }}</th>
                        <th style="width: 5%;" scope="col">{{ $t('offer_list.table.headers.boiler_power') }}</th>
                        <th style="width: 20%;" scope="col">{{ $t('offer_list.table.headers.selection') }}</th>
                        <th style="width: 10%;" scope="col">{{ $t('offer_list.table.headers.total_price') }}</th>
                        <th style="width: 20%;" scope="col">{{ $t('configurations_all.int_name') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.validate') }}</th>
                        <th scope="col">{{ $t('offer_list.table.headers.delete') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="configuration in configurationStore" :key="configuration.id">
                        <td>{{ configuration.societe }}</td>
                        <td>{{ configuration.utilisateur_nom }} {{ configuration.utilisateur_prenom }}</td>
                        <td>{{ configuration.nom_projet }}</td>
                        <td>{{ configuration.lieu_projet }}</td>
                        <td>{{ formatDate(configuration.date_creation) }}</td>
                        <td>{{ configuration.valeur_debit_brut }} Nm³/h</td>
                        <td>{{ configuration.deb_hum_max }} Nm³/h</td>
                        <td>{{ configuration.deb_hum_max_evo }} Nm³/h</td>
                        <td v-if="configuration.puissance_chaudiere">{{ configuration.puissance_chaudiere }} kW</td>
                        <td v-else>x</td>
                        <td>{{ configuration.nom_commercial }}</td>
                        <td>{{ formatNumber(configuration.total) }} €</td>
                        <td>{{ configuration.interlocuteur_nom }} {{ configuration.interlocuteur_prenom }}</td>
                        <td style="width: 1.5%;"><i @click="validateOffer(configuration.id)" class="bi bi-check-circle-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteOffer(configuration.id)" class="bi bi-trash3-fill"></i></td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('offer_list.table.no_offers') }}</div>
            <button class="btn btn-primary" @click="loadConfigurations" v-if="hasMoreConfigurations">{{ $t('offer_list.buttons.load_more') }}</button>
        </div>
    </div>
</template>

<script>

import { useConfigurationsStore } from '@/store/configurations.store';

export default {
    data() {
        return {
            users: [],
            message: '',
            userEditing: null,
            searchQuery: '',
            groups: [],
            contacts: [],
        };
    },
    computed: {
        configurationStore() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.configurations;
        },
     
        hasMoreConfigurations() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.hasMoreConfigurations;
        }
    },
    mounted() {
        if (this.configurationStore.length === 0) {
            this.loadConfigurations(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {
 
        loadConfigurations() {
            const configurationsStore = useConfigurationsStore();
            configurationsStore.loadConfigurations();
        },
        deleteOffer(offerDeleting) {

            const deletedOffer = this.configurationStore.find(configuration => configuration.id === offerDeleting);

            const configurationsStore = useConfigurationsStore();
            configurationsStore.deleteOffer(deletedOffer);

        },
        validateOffer(offerValidating) {
            console.log(offerValidating);
            const validatedOffer = this.configurationStore.find(configuration => configuration.id === offerValidating);

            if (!validatedOffer) {
                console.error("Offre non trouvée");
                return;
            }            

            const configurationsStore = useConfigurationsStore();
            configurationsStore.validateOffer(validatedOffer);


        },
        handleSearch() {
            const configurationsStore = useConfigurationsStore();
            configurationsStore.params.query = this.searchQuery;
            configurationsStore.loadConfigurations(true); // Reset and load offers based on the search query
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        formatNumber(num) {
            if (num == null) return '--'; // Retourne un placeholder ou une valeur par défaut
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

    },
    beforeUnmount() {
        const configurationsStore = useConfigurationsStore();
        configurationsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
.titre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
</style>