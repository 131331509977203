<template>
    
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>Ajouter une chaudière</h2>
        </div>
        <div class="body">
            <div class="mb-3">
                <label for="InputPuissance" class="form-label">Puissance</label>
                <input type="number" v-model="chaudiere.puissance" placeholder="Puissance" class="form-control" id="InputPuissance" required>
            </div>
            <div class="mb-3">
                <label for="InputPrix" class="form-label">Prix</label>
                <input type="number" v-model="chaudiere.prix" placeholder="Prix" class="form-control" id="InputPrix" required>
            </div>
            <div class="mb-3">
                <label for="InputPrixBruleur" class="form-label">Prix du bruleur mixte</label>
                <input type="number" v-model="chaudiere.prix_bruleur_mixte" placeholder="Prix du bruleur mixte" class="form-control" id="InputPrixBruleur" required>
            </div>
            <button type="submit" class="btn btn-primary">Ajouter la chaudière</button>
        </div>
    </form>

</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useChaudieresStore } from '@/store/chaudieres.store';

export default {
    data() {
        return {
            chaudiere: {
                puissance: '',
                prix: '',
                prix_bruleur_mixte: ''
            },
            message: '',
        };
    },

    methods: {
        submitForm() {  // Ensure a closing parenthesis here
    
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/chaudieres', this.chaudiere, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedChaudiere = {
                        ...this.chaudiere,
                        id: response.data.id  // Ajoutez l'`id` à l'objet user
                    };
                    const chaudieresStore = useChaudieresStore();
                    chaudieresStore.addChaudiere(addedChaudiere);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.chaudiere.puissance = '';
                    this.chaudiere.prix = '';
                    this.chaudiere.prix_bruleur_mixte = '';
                });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>