<script setup>
  import OfferList from '@/components/offres/OfferList.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <OfferList/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

