<template>
    <div class="intro" v-show="currentForm != 8">
        <p>
            {{ $t('configurator.fill_info') }}
        </p>
        <p class="danger" role="alert">
            {{ $t('configurator.do_not_reload') }}
        </p>
    </div>

    <div class="navConfig"  v-show="currentForm != 8">
        <ul>
            <li class="item1" :class="getNavClass(1)">1</li>
            <li class="item2" :class="getNavClass(2)">2</li>
            <li class="item3" :class="getNavClass(3)">3</li>
            <li class="item4" :class="getNavClass(4)">4</li>
            <li class="item5" :class="getNavClass(5)">5</li>
            <li class="item6" :class="getNavClass(6)">6</li>
            <li class="item7 last" :class="getNavClass(7)">7</li>
        </ul>
    </div>
    
    <form class="form1 white-bg" @submit.prevent="submitForm" v-show="currentForm == 1">
        <h2>{{ $t('configurator.general_info') }}</h2>
        <div class="body">
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-12">
                        <label for="InputNomProjet" class="form-label">{{ $t('configurator.project_name') }}</label>
                        <input type="text" v-model="configurations.nom_projet" :placeholder="$t('configurator.project_name')" class="form-control" id="InputNomProjet" required @keydown.enter.prevent>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-12">
                        <label for="InputLieuxProjet" class="form-label">{{ $t('configurator.project_location') }}</label>
                        <input type="text" v-model="configurations.lieu_projet" :placeholder="$t('configurator.project_location')" class="form-control" id="InputLieuxProjet" required @keydown.enter.prevent>
                    </div>
                </div>
            </div>
            <input type="date" v-model="configurations.date_creation" placeholder="Nom" class="form-control" id="InputDateCreation" required readonly @keydown.enter.prevent style="display: none;">
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-4">
                        <label for="InputNomChaudiere" class="form-label">{{ $t('configurator.need_boiler') }}</label>
                        <div class="radioContainer">
                            <input type="radio" id="chaudiereOui" name="chaudiere" value="oui" v-model="configurations.chaudiere" required @keydown.enter.prevent>
                            <label for="chaudiereOui">{{ $t('configurator.yes') }}</label>
                            <input type="radio" id="chaudiereNon" name="chaudiere" value="non" v-model="configurations.chaudiere" required @keydown.enter.prevent>
                            <label for="chaudiereNon">{{ $t('configurator.no') }}</label>
                        </div>
                    </div>
                    <div class="col-lg-4" v-show="configurations.chaudiere === 'oui'">
                        <label for="InputFourniture" class="form-label">{{ $t('configurator.arol_supply') }}</label>
                        <div class="radioContainer">
                            <input type="radio" id="fournitureOui" name="fourniture" value="oui" v-model="configurations.fourniture" :required="configurations.chaudiere === 'oui'" @keydown.enter.prevent>
                            <label for="fournitureOui">{{ $t('configurator.yes') }}</label>
                            <input type="radio" id="fournitureNon" name="fourniture" value="non" v-model="configurations.fourniture" :required="configurations.chaudiere === 'oui'" @keydown.enter.prevent>
                            <label for="fournitureNon">{{ $t('configurator.no') }}</label>
                        </div>
                        <p class="note">{{ $t('configurator.chaudiere_note') }}</p>
                    </div>
                    <div class="col-lg-4" v-show="configurations.fourniture && configurations.chaudiere">
                        <label for="InputPuissance" class="form-label">{{ $t('configurator.boiler_power') }}</label>
                        <select class="form-control" id="InputPuissance" v-model="configurations.puissance" :required="configurations.fourniture === 'oui' && configurations.chaudiere === 'oui'" @keydown.enter.prevent>
                            <option value="">{{ $t('configurator.select_power') }}</option>
                            <option v-for="chaudiere in chaudieres" :key="chaudiere.id" :value="chaudiere.puissance">{{ chaudiere.puissance }} kWth</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-4">
                        <label for="InputDebitNominal" class="form-label">{{ $t('configurations.nominal_biogas_flow') }}</label>
                        <div class="radioContainer">
                            <input type="radio" id="debitNominalBrut" name="debitNominal" value="brut" v-model="configurations.debit_nominal" required @keydown.enter.prevent>
                            <label for="debitNominalBrut">{{ $t('configurator.brut') }}</label>
                            <input type="radio" id="debitNominalSec" name="debitNominal" value="sec" v-model="configurations.debit_nominal" required @keydown.enter.prevent>
                            <label for="debitNominalSec">{{ $t('configurator.sec') }}</label>
                        </div>
                    </div>
                    <div class="col-lg-4" v-show="configurations.debit_nominal === 'brut'">
                        <label for="InputValeurDebitBrut" class="form-label">{{ $t('configurator.brut_biogas_flow_value') }} (Nm³/h)</label>
                        <input type="number" v-model="configurations.valeur_debit_brut" :placeholder="$t('configurator.brut_biogas_flow_value')" class="form-control" id="InputValeurDebitBrut" :required="configurations.debit_nominal === 'brut'" @keydown.enter.prevent>
                    </div>
                    <div class="col-lg-4" v-show="configurations.debit_nominal === 'sec'">
                        <label for="InputValeurDebitSec" class="form-label">{{ $t('configurator.sec_biogas_flow_value') }} (Nm³/h)</label>
                        <input type="number" v-model="configurations.valeur_debit_sec" :placeholder="$t('configurator.sec_biogas_flow_value')" class="form-control" id="InputValeurDebitSec" :required="configurations.debit_nominal === 'sec'" @keydown.enter.prevent>
                    </div>
                </div>
            </div>
            <input type="hidden" v-model="configurations.utilisateur" id="InputUtilisateurEmail">
            <div class="buttons">
                <button type="submit" class="btn btn-primary">{{ $t('configurator.next_step') }}</button>
            </div>
        </div>
    </form>

    <form class="form2 white-bg" @submit.prevent="submitForm2" v-show="currentForm == 2">
        <h2>{{ $t('configurator.biogas_composition') }}</h2>
        <div class="body">
            <div class="part1">
                <div class="row">
                    <div class="col-lg-4">
                        <h3>{{ $t('configurator.brut_biogas') }}</h3>
                        <div v-if="configuration">
                            <ul>
                                <li><strong>CH4:</strong> {{ percent_CH4_hum }}%</li>
                                <li><strong>CO2:</strong> {{ configuration.percent_CO2_hum }}%</li>
                                <li><strong>O2:</strong> {{ percent_O2_hum }}%</li>
                                <li><strong>N2:</strong> {{ percent_N2_hum }}%</li>
                                <li><strong>H2O:</strong> {{ percent_H2O_hum }}%</li>
                                <li><strong>{{ $t('configurator.nominal_biogas_flow_brut') }}:</strong> {{ configuration.valeur_debit_brut }} Nm³/h</li>
                            </ul>
                        </div>
                        <div v-else>
                            <p>{{ $t('configurator.loading') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <h3>{{ $t('configurator.sec_biogas') }}</h3>
                        <div v-if="configuration">
                            <ul>
                                <li><strong>CH4:</strong> {{ percent_CH4_sec }}%</li>
                                <li><strong>CO2:</strong> {{ configuration.percent_CO2_sec }}%</li>
                                <li><strong>O2:</strong> {{ configuration.percent_O2_sec }}%</li>
                                <li><strong>N2:</strong> {{ percent_N2_hum }}%</li>
                                <li><strong>H2O:</strong> {{ percent_H2O_sec }}%</li>
                                <li><strong>{{ $t('configurator.nominal_biogas_flow_sec') }}:</strong> {{ configuration.valeur_debit_sec }} Nm³/h</li>
                            </ul>
                        </div>
                        <div v-else>
                            <p>{{ $t('configurator.loading') }}</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <h3>{{ $t('configurator.biomethane') }}</h3>
                        <div v-if="configuration">
                            <p><strong>{{ $t('configurator.nominal_biomethane_flow') }}:</strong> {{ configuration.deb_biomet_nom }} Nm³/h</p>
                        </div>
                        <div v-else>
                            <p>{{ $t('configurator.loading') }}</p>
                        </div>
                    </div>
                </div>
                <p class="inf part1Sentence">{{ $t('configurator.nominal_biomethane_flow_info') }}</p>
            </div>
            <div class="part2">
                <div class="mb-3">
                    <div class="row">
                        <p class="inf">{{ $t('configurator.recommendation') }}</p>
                        <div class="col-lg-12">
                            <label for="InputSurcapacite" class="form-label">{{ $t('configurator.surcapacity') }} ({{ $t('configurator.max_value') }} 40%)</label>
                            <input
                            type="text"
                            v-model="formattedSurcapa"
                            :placeholder="$t('configurator.surcapacity_placeholder')"
                            class="form-control surcapPercent"
                            id="InputSurcapacite"
                            @keypress="allowOnlyNumbers"
                            @input="formatInput"
                            @blur="addPercentage"
                            @keydown.enter.prevent
                          >
                            <p class="note">{{ $t('configurator.surcapacity_note') }}</p>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4">
                            <p><strong>{{ $t('configurator.max_biogas_brut_flow') }}:</strong> {{ deb_hum_max }} Nm³/h</p>
                        </div>
                        <div class="col-lg-4">
                            <p><strong>{{ $t('configurator.max_biogas_sec_flow') }}:</strong> {{ deb_sec_max }} Nm³/h</p>
                        </div>
                        <div class="col-lg-4">
                            <p><strong>{{ $t('configurator.max_biomethane_flow') }}:</strong> {{ deb_biomet_max }} Nm³/h</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
                <button type="submit" class="btn btn-primary">{{ $t('configurator.next_step') }}</button>
            </div>
        </div>
    </form>

    <form class="form3 white-bg" @submit.prevent="submitForm3" v-show="currentForm == 3">
        <h2>{{ $t('configurator.project_data') }}</h2>
        <div class="body donnees">
            <div class="row">
                <div class="col-lg-12">
                    <ul>
                        <li v-if="configurations.debit_nominal =='brut'"><strong>{{ $t('configurator.nominal_biogas_flow_brut') }} : </strong>{{ valeur_debit_brut }} Nm³/h</li>
                        <li v-else><strong>{{ $t('configurator.nominal_biogas_flow_sec') }} : </strong>{{ configurations.valeur_debit_sec }} Nm³/h</li>

                        <li><strong>{{ $t('configurator.nominal_biomethane_flow') }} : </strong>{{ deb_biomet_nom }} Nm³/h</li>
                        <li><strong>{{ $t('configurator.max_biogas_brut_flow') }} : </strong>{{ deb_hum_max }} Nm³/h</li>
                        <li><strong>{{ $t('configurator.max_biomethane_flow') }} : </strong>{{ deb_biomet_max }} Nm³/h</li>
                    </ul>
                </div>
                <p class="note">{{ $t('configurator.biomethane_note') }}</p>
            </div>
        </div>

        <!--<div class="body body2"  v-show="configurations.puissance">
            <h2>Chaudière</h2>
            <div class="row">
                <div class="col-lg-12">
                    <p v-show="deb_chaud_sec"><strong>Débit chaudière sec : </strong>{{ deb_chaud_sec }} Nm³/h</p>
                    <p v-show="deb_chaud_brut"><strong>Débit chaudière brut : </strong>{{ deb_chaud_brut }} Nm³/h</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <label for="inputDebutChaudiere" class="form-label">Souhaitez-vous prendre en compte le débit de chaudière ?</label>
                    <select v-model="configurations.includeChaudiereDebut" id="inputDebutChaudiere" class="form-control" @change="updateConfiguration">
                        <option value="oui">Oui</option>
                        <option value="non">Non</option>
                    </select>
                </div>
            </div>
            <div class="row" v-if="configurations.includeChaudiereDebut === 'oui'">
                <div class="col-lg-12">
                    <p class="resultWithChaudiere"><strong>Débit biogaz brut maximum (chaudière incluse) : </strong>{{ deb_BG_hum_max }} Nm³/h</p>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-lg-12">
                    <p class="resultWithChaudiere"><strong>Débit biogaz brut maximum : </strong>{{ deb_hum_max }} Nm³/h</p>
                </div>
            </div>
            
        </div>-->

        <h2>{{ $t('configurator.scalability') }}</h2>
        <div class="body">
            <div class="row">
                <div class="col-lg-12">
                    <p>{{ $t('configurator.scalability_info') }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <label for="InputDebitBiogazFutur" class="form-label">{{ $t('configurator.future_biogas_flow') }} ({{ $t('configurator.scalability') }})</label>
                    <input type="number" v-model="configurations.deb_hum_max_evo" :placeholder="$t('configurator.future_biogas_flow_placeholder')" class="form-control" id="InputDebitBiogazFutur" @keydown.enter.prevent :min="deb_hum_max" @input="validateValue(configurations.deb_hum_max_evo)">
                    <p v-if="errorMessage" class="error-message" v-html="errorMessage"></p>
                </div>
                <div class="col-lg-4">
                    <label for="InputPercentHumMaxEvo" class="form-label">{{ $t('configurator.scalability_percentage') }}</label>
                    <input type="number" v-model="percent_hum_max_evo" :placeholder="$t('configurator.scalability_placeholder')" class="form-control noInput" id="InputPercentHumMaxEvo" readonly @keydown.enter.prevent>
                </div>
                <div class="col-lg-4">
                    <label for="InputBiometMaxEvo" class="form-label">{{ $t('configurator.max_biomethane_flow_evo') }} (Nm³/h)</label>
                    <input type="number" v-model="deb_biomet_max_evo" :placeholder="$t('configurator.max_biomethane_flow_evo_placeholder')" class="form-control noInput" id="InputBiometMaxEvo" readonly @keydown.enter.prevent>
                </div>
            </div>
            <div class="buttons">
                <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
                <button type="submit" class="btn btn-primary">{{ $t('configurator.next_step') }}</button>
            </div>
        </div>
    </form>
    

    <div class="form4" v-show="currentForm == 4">
        <div class="row donneesEvo">
            <div class="col-lg-6">
                <h2>{{ $t('configurator.project_data') }}</h2>
                <div class="body">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul>
                                <li v-if="configurations.debit_nominal =='brut'"><strong>{{ $t('configurator.nominal_biogas_flow_brut') }} : </strong>{{ valeur_debit_brut }} Nm³/h</li>
                                <li v-else><strong>{{ $t('configurator.nominal_biogas_flow_sec') }} : </strong>{{ configurations.valeur_debit_sec }} Nm³/h</li>

                                <li><strong>{{ $t('configurator.nominal_biomethane_flow') }} : </strong>{{ deb_biomet_nom }} Nm³/h</li>
                                <li><strong>{{ $t('configurator.max_biogas_brut_flow') }} : </strong>{{ deb_hum_max }} Nm³/h</li>
                                <li><strong>{{ $t('configurator.max_biomethane_flow') }} : </strong>{{ deb_biomet_max }} Nm³/h</li>
                            </ul>
                        </div>
                        <p class="note">{{ $t('configurator.biomethane_note') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <h2>{{ $t('configurator.scalability_data') }}</h2>
                <div class="body">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul>
                                <li><strong>{{ $t('configurator.max_biogas_brut_flow_evo') }} : </strong>{{ deb_hum_max_evo }} Nm³/h</li>
                                <li><strong>{{ $t('configurator.max_biomethane_flow_evo') }} : </strong>{{ deb_biomet_max_evo }} Nm³/h</li>
                            </ul>
                        </div>
                        <p class="note">{{ $t('configurator.biomethane_note') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <h2>{{ $t('configurator.possible_configurations') }}</h2>
            <div class="body">
                <div class="row">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:16.66%;">{{ $t('configurator.equipment_selection') }}</th>
                                <th style="width:16.66%;">{{ $t('configurator.compressor_power') }} (Nm³/h)</th>
                                <th style="width:16.66%;">{{ $t('configurator.min_biogas_brut_flow') }} (Nm³/h)</th>
                                <th style="width:16.66%;">{{ $t('configurator.max_biogas_brut_flow') }} (Nm³/h)</th>
                                <th style="width:16.66%;">{{ $t('configurator.project_biogas_flow') }} (Nm³/h)</th>
                                <th style="width:16.66%;">{{ $t('configurator.equipment_price') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="selection in selectionsTable" :key="selection.variation_id">
                                <td>{{ selection.equip }}</td>
                                <td>{{ selection.puissance_compresseur }}</td>
                                <td>{{ selection.debit_mini_biogaz_brut_equip }} Nm³/h</td>
                                <td>{{ selection.debit_max_biogaz_brut_equip }} Nm³/h</td>
                                
                                <td v-if="selection.typeConfig == 'max'">{{ deb_hum_max_evo }} Nm³/h  <br><strong>(Avec évolutivité)</strong></td>
                                <td v-if="selection.typeConfig == 'normal'">{{ valeur_debit_brut }} Nm³/h <br><strong>(Sans évolutivité)</strong></td>
                                
                                <td v-if="selection.prix_equipement">
                                    <div v-if="reduction">
                                        <span>{{ formatNumber(selection.prix_equipement * (1 + reduction / 100)) }}€</span>
                                    </div>
                                    <div v-else>
                                       <span>{{ formatNumber(selection.prix_equipement) }}€</span>
                                    </div>
                                </td>                                
                                <td v-else>{{ $t('configurator.contact_us') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row selectConfig">
                    <label for="InputSelection" class="form-label">{{ $t('configurator.select_configuration') }}</label>
                    <select class="form-control" id="InputSelection" v-model="configurations.selection" @keydown.enter.prevent>
                        <option value="">{{ $t('configurator.select_desired_configuration') }}</option>
                        <option 
                            v-for="selection in selectionsTable" 
                            :key="selection.variation_id" 
                            :value="`${selection.variation_id}|${selection.equip}`"
                            >
                            {{ selection.equip }} 
                            {{ $t('configurator.max_biogas_brut_flow') }} 
                            {{ selection.debit_max_biogaz_brut_equip }} - 
                            {{ $t('configurator.project_biogas_flow') }} 
                            <span v-if="selection.typeConfig == 'max'">
                                {{ deb_hum_max_evo }} Nm³/h
                            </span>
                            <span v-else-if="selection.typeConfig == 'normal'">
                                {{ valeur_debit_brut }} Nm³/h
                            </span>
                        </option>
                    </select>
                </div>
                <div v-if="selectedEquip" class="row apercu">
                    <img v-if="configurations.chaudiere === 'oui'" class="apercu-installation" :alt="$t('configurator.installation_preview')" :src="`https://arol-energy.com/app/uploads/${selectedEquip}/photo_avec_chaudiere.png`">
                    <img v-else class="apercu-installation" :alt="$t('configurator.installation_preview')" :src="`https://arol-energy.com/app/uploads/${selectedEquip}/photo_sans_chaudiere.png`">
                </div>
            </div>
        </div>
        
        <div class="buttons">
            <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
            <button class="btn btn-primary" @click="submitForm4">{{ $t('configurator.validate_configuration') }}</button>
        </div>
    </div>

    <form class="form5 white-bg" @submit.prevent="submitForm5" v-show="currentForm == 5">
        <h2>{{ $t('configurator.network_determination') }}</h2>
        <div class="body">
            <div class="row d-flex align-items-end">
                <div class="reseau">
                    <label for="InputPressionInjection" class="form-label">{{ $t('configurator.injection_pressure') }}</label>
                    <input type="number" v-model="configurations.P_injection" :placeholder="$t('configurator.injection_pressure')" class="form-control" id="InputPressionInjection" @keydown.enter.prevent step="0.1" required>
                </div>

                <div class="reseau">
                    <label for="DistanceEpurateurMethanisation" class="form-label">{{ $t('configurator.distance_epurateur_methanisation') }} (ml*)</label>
                    <input type="number" v-model="configurations.D_metha" :placeholder="$t('configurator.distance_epurateur_methanisation_placeholder')" class="form-control" id="DistanceEpurateurMethanisation" @keydown.enter.prevent step="0.1" required>
                </div>

                <div class="reseau">
                    <label for="DistanceEpurateurInjection" class="form-label">{{ $t('configurator.distance_epurateur_injection') }} (ml*)</label>
                    <input type="number" v-model="configurations.D_injection" :placeholder="$t('configurator.distance_epurateur_injection_placeholder')" class="form-control" id="DistanceEpurateurInjection" @keydown.enter.prevent step="0.1" required>
                </div>
            </div>

            <div class="row">
                <p class="note">{{ $t('configurator.noteMl') }}</p>
            </div>

            <img class="determination-reseaux" :alt="$t('configurator.network_determination')" src="../../assets/determination-reseaux.png">

            <div class="buttons">
                <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
                <button type="submit" class="btn btn-primary">{{ $t('configurator.next_step') }}</button>
            </div>
        </div>
    </form>

    <form class="form6 white-bg" @submit.prevent="submitForm6" v-show="currentForm == 6">
        <h2>{{ $t('configurator.options_list') }}</h2>
        <div class="body">
            <div class="row">
                <div class="col-lg-12">
                    <p>{{ $t('configurator.prechecked_options') }}</p>
                </div>
            </div>
            <div class="row">
                <div v-for="nomTypeOption in uniqueNomTypeOptions" :key="nomTypeOption">
                    <h3>{{ nomTypeOption }}</h3>
                                        
                    <img v-if="nomTypeOption === 'Options côté poste d\'injection'" :src="containsOption(filteredOptions(nomTypeOption), 30) ? require('../../assets/haute-injection-image.jpg') : require('../../assets/injection-image.jpg')" :alt="$t('configurator.injection_side')" class="image-injection" />

                    <table>
                        <thead>
                            <tr>
                                <th style="width: 70%;">{{ $t('configurator.equipment') }}</th>
                                <th style="width: 30%;">{{ $t('configurator.price_excl_vat') }} (€)</th>
                                <th style="width: 10%;">{{ $t('configurator.select') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="option in filteredOptions(nomTypeOption)" :key="option.id">
                                <td>{{ option.nom }}</td>
                                <td v-if="option.id == 30">Compression haute pression nous consulter</td>
                                <td v-else >{{ formatNumber(option.prix_total) }} €</td>
                                <td>
                                    <input class="empty" v-if="option.id == 30" type="checkbox" :value="option.id" @change="toggleOption(option.id, $event)" checked disabled>
                                    <input v-else-if="option.id == 26 && configurations.fourniture === 'oui'" type="checkbox" :value="option.id" @change="toggleOption(option.id, $event)" checked disabled>
                                    <input v-else type="checkbox" :value="option.id" @change="toggleOption(option.id, $event)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="buttons">
                <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
                <button type="submit" class="btn btn-primary">{{ $t('configurator.next_step') }}</button>
            </div>
        </div>
    </form>

    <div class="form7" @submit.prevent="submitForm7" v-show="currentForm == 7">
        <h2>{{ $t('configurator.recap_price') }}</h2>
        
        <div v-for="nomTypeOption in uniqueNomTypeOptionsRecap" :key="nomTypeOption">
            <h3>{{ nomTypeOption }}</h3>
            <table>
                <thead>
                    <tr>
                        <th style="width: 80%;">{{ $t('configurator.equipment') }}</th>
                        <th style="width: 20%;">{{ $t('configurator.price_excl_vat') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="option in filteredSelectedOptionsRecap(nomTypeOption)" :key="option.id">
                
                        <td>{{ option.nom }}</td>
                        <td v-if="option.nom == 'Total général'"><span class="total">{{ formatNumber(option.total) }} €</span></td>
                        <td v-else-if="option.nom == 'Réseaux biométhane'">Nous consulter</td>
                        <td v-else>{{ formatNumber(option.total) }} €</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
           <div class="">
                <div class="row justify-content-center">
                   <div class="col-lg-12">
                        <label for="InputCommentaireFinal" class="form-label">{{ $t('configurator.add_comment') }}</label>
                        <div class="radioContainerCom">
                            <input type="radio" id="comOui" name="commentaire" value="oui" v-model="configurations.commentaire" required @keydown.enter.prevent>
                            <label for="comOui">{{ $t('configurator.yes') }}</label>
                            <input type="radio" id="comNon" name="commentaire" value="non" v-model="configurations.commentaire" required @keydown.enter.prevent>
                            <label for="comNon">{{ $t('configurator.no') }}</label>
                        </div>
                   </div>
                </div>
                <div class="row justify-content-center" v-if="configurations.commentaire === 'oui'">
                    <div class="col-lg-8">
                        <label for="commentaireText" class="form-label">{{ $t('configurator.comment_placeholder') }}</label>
                        <textarea id="commentaireText" v-model="configurations.commentaireText" class="form-control" rows="4" :placeholder="$t('configurator.enter_comment_here')"></textarea>
                    </div>
                </div>
           </div>
        </div>
       
        <div class="buttons">
            <button type="button" class="btn btn-primary" @click="goBack">{{ $t('configurator.back') }}</button>
            <button class="btn btn-primary" @click="submitForm7(id_configuration)">{{ $t('configurator.confirm_offer') }}</button>
        </div>
    </div>
    <div class="form8" v-show="currentForm == 8">
        <div class="confirmation">
            <img class="confirmation-image" :alt="$t('configurator.confirmation_icon')" src="../../assets/confirmation.png">
            <h2>{{ $t('configurator.success') }}</h2>
            <p>{{ $t('configurator.submission_message') }}</p>
            <p class="dem">{{ $t('configurator.email_summary_message') }}</p>
            <router-link class="btn-secondary" to="/tableau-de-bord-client"><i class="bi bi-speedometer"></i>{{ $t('configurator.back_to_dashboard') }}</router-link>
        </div>
    </div>
</template>

<script>
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useAuthStore } from '@/store/auth.store';
import { useConfigurationsStore } from '@/store/configurations.store';
import { useProductsStore } from '@/store/products.store';

export default {
    data() {
        return {
            configuration: null,
            variables: [],
            selectionsTable: [],
            reduction: 0,
            configurations: {
                utilisateur: '',
                utilisateur_nom: '',
                utilisateur_prenom: '',
                interlocuteur_nom: '',
                interlocuteur_prenom: '',
                societe: '',
                nom_projet: '',
                lieu_projet: '',
                date_creation: new Date().toISOString().substring(0, 10),
                chaudiere: '',
                fourniture: '',
                puissance: '',
                debit_nominal: '',
                valeur_debit_brut: '',
                valeur_debit_sec: '',
                percent_surcapa: '',
                deb_hum_max: '',
                deb_sec_max: '',
                deb_hum_max_evo: '',
                percent_hum_max_evo: '',
                deb_biomet_max_evo: '',
                selection: '',
                includeChaudiereDebut: 'non',
                P_injection: '',
                D_injection: '',
                D_metha: '',
                selectedOptions: [],
                commentaire: '',
                commentaireText: '',
                termine: false,
            },
            chaudieres: [],
            options: [],
            uniqueNomTypeOptions: [],
            currentForm: 1,
            message: '',
            id: null,
            errorMessage: ''
            
        };
    },
    watch: {
        'configurations.fourniture'(newVal) {
            if (newVal === 'oui') {
                // Ajoute 26 à `selectedOptions` s'il n'est pas déjà inclus
                if (!this.configurations.selectedOptions.includes(26)) {
                    this.configurations.selectedOptions.push(26);
                }
            } else {
                // Retire 26 de `selectedOptions` si chaudiere != "oui"
                this.configurations.selectedOptions = this.configurations.selectedOptions.filter(id => id !== 26);
            }

            // Si chaudiere vaut 'non', vider fourniture et puissance
            if (newVal === 'non') {
                this.configurations.puissance = '';
            }
        },
        'configurations.P_injection'(newVal) {
            if (newVal > 16) {
                // Ajoute 26 à `selectedOptions` s'il n'est pas déjà inclus
                if (!this.configurations.selectedOptions.includes(30)) {
                    this.configurations.selectedOptions.push(30);
                }
            } else {
                // Retire 26 de `selectedOptions` si chaudiere != "oui"
                this.configurations.selectedOptions = this.configurations.selectedOptions.filter(id => id !== 30);
            }

            // Si chaudiere vaut 'non', vider fourniture et puissance
            if (newVal === 'non') {
                this.configurations.puissance = '';
            }
        },
        'configurations.chaudiere'(newVal) {
            if (newVal === 'non') {
                this.configurations.fourniture = '';
                this.configurations.puissance = '';
            }
        },
        'configurations.debit_nominal'(newVal) {
            if (newVal === 'brut') {
                this.configurations.valeur_debit_sec = '';
            } else if (newVal === 'sec') {
                this.configurations.valeur_debit_brut = '';
            }
        },
        'configurations.percent_surcapa'() {
            this.updateDebMax();
        },
        'configurations.deb_hum_max_evo'(newVal) {
            this.updateDebMax();
            this.updateDebHumMaxEvoInStore(newVal);
        },
        'configurations.deb_hum_max'(newVal) {
            this.updateDebHumMaxInStore(newVal);
        },
    },
    mounted() {
        this.loadChaudieres();
        this.setUserEmail();
        this.setUserCompany();
        this.loadVariables();
        this.loadOptions();
        this.setUserName();
        this.setUserFirstName();
        this.setUserIntName();
        this.setUserIntFirstName();
    },
    computed: {
        // Variables que je récupère
        deb_hum_max() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_hum_max;
        },
        deb_sec_max() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_sec_max;
        },
        valeur_debit_brut() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.valeur_debit_brut;
        },
        deb_biomet_nom() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_biomet_nom;
        },
        deb_biomet_max() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_biomet_max;
        },
        fourniture() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.fourniture;
        },
        deb_chaud_sec() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_chaud_sec;
        },
        deb_chaud_brut() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_chaud_brut;
        },
        deb_BG_hum_max() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_BG_hum_max;
        },
        deb_hum_max_evo() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_hum_max_evo;
        },
        percent_hum_max_evo() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.percent_hum_max_evo;
        },
        deb_biomet_max_evo() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.deb_biomet_max_evo;
        },
        selections() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.selections;
        },
        id_configuration() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.id_configuration;
        },
        selectedOptionsListDef() {
            const configurationsStore = useConfigurationsStore();
            return configurationsStore.selectedOptionsListDef;
        },
        uniqueNomTypeOptionsRecap() {
            // Récupérer les valeurs uniques de `nom_type_option` dans `selectedOptionsListDef`
            const selectedOptions = this.selectedOptionsListDef || [];
            const uniqueOptions = [...new Set(selectedOptions.map(option => option.nom_type_option))];
            return uniqueOptions;
        },




        percent_CH4_hum() {
            const variable = this.variables.find(v => v.nom === 'percent_CH4_hum');
            return variable ? variable.valeur : 'N/A';
        },
        percent_CO2_hum() {
            const variable = this.variables.find(v => v.nom === 'percent_CO2_hum');
            return variable ? variable.valeur : 'N/A';
        },
        percent_O2_hum() {
            const variable = this.variables.find(v => v.nom === 'percent_O2_hum');
            return variable ? variable.valeur : 'N/A';
        },
        percent_N2_hum() {
            const variable = this.variables.find(v => v.nom === 'percent_N2_hum');
            return variable ? variable.valeur : 'N/A';
        },
        percent_H2O_hum() {
            const variable = this.variables.find(v => v.nom === 'percent_H2O_hum');
            return variable ? variable.valeur : 'N/A';
        },
        percent_CH4_sec() {
            const variable = this.variables.find(v => v.nom === 'percent_CH4_sec');
            return variable ? variable.valeur : 'N/A';
        },
        percent_H2O_sec() {
            const variable = this.variables.find(v => v.nom === 'percent_H2O_sec');
            return variable ? variable.valeur : 'N/A';
        },




        productStore() {
            const productsStore = useProductsStore();
            return productsStore.products;
        },
        selectedEquip() {
            const parts = this.configurations.selection.split('|');
            return parts.length > 1 ? parts[1] : '';
        },


        user() {
            const authStore = useAuthStore();
            return authStore.user;
        },
       
    },
    methods: {
        loadConfigurations() {
            const configurationsStore = useConfigurationsStore();
            configurationsStore.loadConfigurations();
        },
        getConfigurationInfos() {
    

            axios.get(`https://arolenergy-05673c117bbd.herokuapp.com/configurations/${this.id}`, {
               
            })
            .then(response => {
                this.configuration = response.data[0]; // Assigne le premier élément de la réponse à `this.configuration`
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
            });
        },
        updateConfiguration() {
            const editedConfiguration = {
                ...this.configurations,
                id: this.id
            };

            const configurationsStore = useConfigurationsStore();
            configurationsStore.updateConfiguration(editedConfiguration);

            this.configurationEditing = null; // Fin de l'édition

            console.log(editedConfiguration);

            
        }, 
        submitForm() {
            if (this.id) {
                // Si l'ID existe, cela signifie que la configuration a déjà été créée, donc on met à jour.
                this.updateConfiguration();
                this.currentForm = 2; // Passe à l'étape suivante
            } else {
                // Si l'ID n'existe pas encore, cela signifie qu'il s'agit de la première création
                axios.post('https://arolenergy-05673c117bbd.herokuapp.com/configurations', this.configurations)
                .then(response => {
                    this.message = response.data.message;
                    this.id = response.data.id; // Stocker l'ID pour la mise à jour future
                    const addedConfiguration = {
                        ...this.configurations,
                        id: response.data.id
                    };
                    const configurationsStore = useConfigurationsStore();
                    configurationsStore.addConfiguration(addedConfiguration);
                    this.showAlertPositive();
                    this.currentForm = 2;
                    this.getConfigurationInfos(); // Charger les informations de la configuration créée
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                });
            }
        },
        submitForm2() {
            this.currentForm = 3;
            this.updateConfiguration();
        },
        submitForm3() {
            this.currentForm = 4;
            this.loadSelection();
        },
        submitForm4() {
            this.currentForm = 5;
            
        },
        submitForm5() {
            this.currentForm = 6;
            this.updateConfiguration();
            this.loadOptions();
        },
        submitForm6() {
            this.currentForm = 7;
            this.updateConfiguration();
            
        },
        submitForm7(id_configuration) {
            this.currentForm = 8;
            this.configurations.termine = true;
            this.updateConfiguration();
            this.loadConfigurations();
            this.generatePdf(id_configuration);
        },
        loadChaudieres() {
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/chaudieres', {
            
            })
            .then(response => {
                this.chaudieres = response.data;
            })
        },
        loadOptions() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/options', {
                params: {
                    P_injection: this.configurations.P_injection,
                    D_injection: this.configurations.D_injection,
                    puissance: this.configurations.puissance,
                    fourniture: this.configurations.fourniture,
                    chaudiere: this.configurations.chaudiere,
                },
               
            })
            .then(response => {
                const options = response.data;
                this.options = [...options]; // Assigne tous les utilisateurs récupérés à `this.users`

                console.log(options)
                // Récupérer les valeurs uniques de `nom_type_option`
                const uniqueNomTypeOptions = [...new Set(options.map(option => option.nom_type_option))];

                console.log(uniqueNomTypeOptions); // Affiche le tableau des valeurs uniques
                // Vous pouvez assigner ce tableau à une propriété de votre composant si besoin
                this.uniqueNomTypeOptions = uniqueNomTypeOptions;
            })
        },
        loadVariables() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/variables', {
               
            })
            .then(response => {
                const variables = response.data;
                this.variables = [...variables]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },
        loadSelection() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/variations/selections', {
                params: { selections: this.selections,   groupe: this.user.groupe },
               
            })
            .then(response => {
                const selections = response.data.selections;
                this.selectionsTable = [...selections]; // Assigne tous les utilisateurs récupérés à `this.users`
                this.reduction = response.data.reduction;
            })
        },
        showAlertPositive() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        setUserCompany() {
            const authStore = useAuthStore();
            this.configurations.societe = authStore.user?.societe || '';
        },
        setUserName() {
            const authStore = useAuthStore();
            this.configurations.prenom = authStore.user?.prenom || '';
        },
        setUserFirstName() {
            const authStore = useAuthStore();
            this.configurations.nom = authStore.user?.nom || '';
        },
        setUserIntName() {
            const authStore = useAuthStore();
            this.configurations.interlocuteur_prenom = authStore.user?.interlocuteur_prenom || '';
        },
        setUserIntFirstName() {
            const authStore = useAuthStore();
            this.configurations.interlocuteur_nom = authStore.user?.interlocuteur_nom || '';
        },
        setUserEmail() {
            const authStore = useAuthStore();
            this.configurations.utilisateur = authStore.user?.email || '';
        },
        goBack() {
          
            if (this.currentForm >= 1) {
                this.currentForm--;
            }
            
        },
        updateDebMax() {
            this.updateConfiguration();
        },
        validateInput(event) {
            if (event.target.value > 40) {
                event.target.value = 40;
                this.configurations.percent_surcapa = 40;
            } else if (event.target.value < 0) {
                event.target.value = 0;
                this.configurations.percent_surcapa = 0;
            } else {
                this.configurations.percent_surcapa = event.target.value;
            }
        },
        getNavClass(step) {
            return {
                active: this.currentForm === step
            };
        },
        loadAvailableConfig() {
            const productsStore = useProductsStore();
            productsStore.loadAvailableConfig();
        },
        updateDebHumMaxInStore(value) {
            const productsStore = useProductsStore();
            productsStore.setDebHumMax(value);
        },
        updateDebHumMaxEvoInStore(value) {
            const productsStore = useProductsStore();
            productsStore.setDebHumMaxEvo(value);
        },


        filteredOptions(nomTypeOption) {
            return this.options.filter(option => option.nom_type_option === nomTypeOption);
        },
        toggleOption(optionId, event) {
            if (event.target.checked) {
                // Ajouter l'id de l'option si la case est cochée
                this.configurations.selectedOptions.push(optionId);
            } else {
                // Retirer l'id de l'option si la case est décochée
                this.configurations.selectedOptions = this.configurations.selectedOptions.filter(id => id !== optionId);
            }
            console.log(this.configurations.selectedOptions);
        },
        filteredSelectedOptionsRecap(nomTypeOption) {
            const selectedOptions = this.selectedOptionsListDef || [];
            return selectedOptions.filter(option => option.nom_type_option === nomTypeOption);
        },
        generatePdf(id) {
    
            const email = this.configurations.utilisateur;

            axios.get(`https://arolenergy-05673c117bbd.herokuapp.com/configurations/pdf/${id}/${email}`, {
               
            })
            .then(response => {
                this.configuration = response.data[0]; // Assigne le premier élément de la réponse à `this.configuration`
                const pdfUrl = `https://arolenergy-05673c117bbd.herokuapp.com/chiffrages/${response.data.url}`;
                this.downloadFile(pdfUrl);
            })
            
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
            });
        },
        downloadFile(pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'configuration.pdf'); // Ajoute l'attribut download avec un nom de fichier
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        validateValue(value) {
            if (value < this.deb_hum_max) {                
                this.errorMessage = `La valeur doit être supérieure ou égale à <b>${this.deb_hum_max}</b>.`;
            } else {
                this.errorMessage = '';
            }
        },
        formatNumber(num) {
            if (num == null) {
                return '';
            }
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        allowOnlyNumbers(event) {
            // Empêche la saisie de tout caractère non numérique
            const charCode = event.which || event.keyCode;
            if (charCode < 48 || charCode > 57) {
            event.preventDefault();
            }
        },
        formatInput() {
            // Supprime les caractères non numériques et garde la valeur brute
            const numericValue = this.formattedSurcapa.replace(/[^0-9]/g, "");
            // Limite la valeur au maximum défini (40 ici)
            const validValue = Math.min(Math.max(parseInt(numericValue, 10) || 0, 0), 40);

            // Met à jour les données avec la valeur brute
            this.configurations.percent_surcapa = validValue;

            // Met à jour l'affichage avec le symbole %
            this.formattedSurcapa = numericValue ? `${validValue}%` : "";
        },
        addPercentage() {
            // Ajoute un % si nécessaire lors de la perte de focus
            if (!this.formattedSurcapa.includes("%") && this.formattedSurcapa !== "") {
            this.formattedSurcapa = `${this.configurations.percent_surcapa}%`;
            }
        },
        containsOption(options, id) {
            return options.some(option => option.id === id);
        },
    },
    beforeUnmount() {
        const configurationsStore = useConfigurationsStore();
        configurationsStore.resetState();
    }
}
</script>
<style lang="scss" scoped>
    $secondary: #2F3937;
    h3 {
        font-size: 1.5rem;
        font-weight: 600 !important;
    }
    h2 {
        margin-bottom: 25px;
    }
    form {
        box-shadow: unset;
        padding: 30px 50px;
        @media screen and (max-width:1499px) {
            padding: 0px;
        }
        
    }
    .radioContainer {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .buttons {
        display: flex;
        gap: 15px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0px;
    }
    .part2 {
        margin: 20px 0px;
    }
    .form3 {
        .body2 {
            margin-top: 40px;
        }
        select {
            width: 100px;
            margin: auto;
            margin-bottom: 20px;
        }
        .resultWithChaudiere {
            background: #FD8543;
            padding: 10px 0px;
        }
   
    }
    .form7 {
        text-align: left;
    }
    .buttons {
        justify-content: center;
        margin-top: 20px;
    }
    .intro {
        width: 45%;
        margin: auto;
        margin-bottom: 50px;
        @media screen and (max-width:1499px) {
            margin-bottom: 25px!important; 
            width: 100%!important;
          }
      }
      .navConfig {
        margin-bottom: 40px;
        @media screen and (max-width:1499px) {
            display: none!important;
          }
        ul {
            display: flex;
            list-style: none;
            padding: 0px;
            margin: 0px;
            gap: 65px;
            justify-content: center;
            .item1::before {
                content: "Infos";
            }
            .item2::before {
                content: "Biogaz";
            }
            .item3::before {
                content: "Évolutivité";
            }
            .item4::before {
                content: "Sélection";
            }
            .item5::before {
                content: "Réseaux";
            }
            .item6::before {
                content: "Options";
            }
            .item7::before {
                content: "Prix";
            }
            li::before {
                transform: translateY(-120%);
                position: absolute;
                top: 0%;
                color: #30A29C;
                font-size: 0.75rem;
                font-weight: 600;
            }
            li {
                position: relative;
                background: #30A29C;
                border-radius: 10px;
                aspect-ratio: 1 / 1;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px;
                text-decoration: none;
                color: #fff;
                opacity: 0.2;
                &::after {
                    content: '';
                    height: 1px;
                    width: 65px;
                    position: absolute;
                    background: #30A29C;
                    left: 100%;
                    opacity: 0.2;
                    z-index: -1;
                }
            }
            .active {
                opacity: 1;
            }
            .last {
                &::after {
                    display: none;
                }
            }
            .config4 {
                &::after {
                display: none;
                }
            }
        }
      }
    .selectConfig {
        margin-top: 15px;
    }
    .apercu {
        img {
            width: 40%;
            margin: auto;
            margin-top: 40px;
            @media screen and (max-width:1499px) {
                width: 90%;
            }
        }
    }
    .total {
        background: #FD8543;
        padding: 10px;
        display: block;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
    }
    .radioContainerCom {
        gap: 5px;
        display: flex;
        margin-bottom: 10px;
    }
    .confirmation {
        width: 40%;
        padding: 70px;
        background: #fff;
        margin: 20px auto;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        @media screen and (max-width:1499px) {
            width: 100%;
            padding: 20px;
        }

        p {
            font-size: 0.95rem!important;
        }
        img {
            height: 80px!important;
            margin-bottom: 20px;
        }
    }
    .download {
        margin: auto;
        margin-bottom: 15px;
    }
    .dem {
        font-weight: 700 !important;
        font-size: 0.90rem !important;
    }
    .danger, .error-message {
        color: #ec6464 !important;
        font-size: 0.9rem !important;
    }
    .part1Sentence {
        margin-top: 1rem;
        border-bottom: 1px solid;
        padding-bottom: 1rem;
    }

    .inf {
        font-size: .85rem !important;
    }

    .donnees {
        border-bottom: 1px solid;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
    .form4 {
        box-shadow: unset;
        padding: 30px 50px;
        text-align: left;
        @media screen and (max-width:992px) {
            padding: 0px;
        }
    }
    .donneesEvo {
        border-bottom: 1px solid;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        align-items: flex-end;
    }
    .reseau {
        margin-bottom: 8px;
    }
    .determination-reseaux {
        margin: auto;
        display: block;
        width: 45%;
        margin-bottom: 35px;
        margin-top: 20px;
    }
    .image-injection {
        width: 80%;
        margin: auto;
        display: block;
    }
    input.empty {
        display: none;
    }
    .surcapPercent {
        width: 20%;
        @media screen and (max-width:992px) {
            width: 100%;
          }
    }
    .noInput {
        background: unset;
        border: 0px;
        padding-left: 0;
        padding-right: 0;
    }
</style>
