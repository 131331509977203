import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://arolenergy-05673c117bbd.herokuapp.com', // URL de base pour toutes les requêtes
    withCredentials: true, // Autoriser l'envoi des cookies ou des credentials
    headers: {
        'Content-Type': 'application/json', // Type de contenu par défaut
    },
});

// Intercepteur pour les requêtes
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Intercepteur pour les réponses
instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            console.error('Erreur Axios - Statut HTTP:', error.response.status);
            console.error('Erreur Axios - Headers:', error.response.headers);
            console.error('Erreur Axios - Data:', error.response.data);
        } else if (error.request) {
            console.error('Erreur Axios - Pas de réponse du serveur:', error.request);
        } else {
            console.error('Erreur Axios - Configuration:', error.message);
        }

        // Gestion globale des erreurs
        const errorMessage = error.response?.data?.message || 'Une erreur réseau est survenue';
        console.error(errorMessage);

        if (error.response?.status === 401) {
            console.warn('Token expiré ou invalide, redirection vers la page de connexion...');
            localStorage.removeItem('token');
        }

        return Promise.reject(error);
    }
);

export default instance;