<script setup>
  import VariablesList from '@/components/variables/VariablesList.vue';
  import OptionList from '@/components/produits/OptionList.vue';
  import AddOption from '@/components/produits/AddOption.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <VariablesList/>
    </div>
    
  </div>

  <div class="row">
    <div class="col-lg-7">
      <OptionList/>
    </div>
    <div class="col-lg-5">
      <AddOption/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

