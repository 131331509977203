import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';

export const useGroupsStore = defineStore("groups", {
    state() {
        return {
            groups: [],
            originalGroups: [],
            params: {
                limit: 6,
                offset: 0,
                query: ''
            },
            hasMoreGroups: true,
            message: ''
        }
    },
    actions: {
        addGroup(newGroup) {
            this.groups.unshift(newGroup);
            this.loadGroups(true);
            console.log(this.hasMoreGroups);
        },
        async loadGroups(reset = false) {  // Ensure a closing parenthesis here
    
              // Réinitialiser la liste si nécessaire
              if (reset) {
                this.groups = [];
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/groups', {
               
            })
            .then(response => {
                const groups = response.data;
                this.originalGroups = [...groups]; // Assigne tous les utilisateurs récupérés à `this.users`
            
                // Met à jour `this.hasMoreUsers` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (groups.length > this.params.limit) {
                    this.hasMoreGroups = true;
                } else {
                    this.hasMoreGroups = false;
                }
                console.log(this.hasMoreGroups)
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreGroups = false; // Assume no more users if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/groups', {
                params: this.params,  
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newGroups = response.data;
                    this.groups.push(...newGroups);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreGroups = false;
                    }
                } else {
                    this.hasMoreGroups = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreGroups = false;  // Assume no more users if there's an error
            });

        },
        async updateGroup(groupData) {
    
            try {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/groups/${groupData.id}`, groupData, {
                    
                })
                .then(response => {
                    console.log(response.data);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.error = error.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
                throw error;
            }
        },
        async deleteGroup(deletedGroup) {
    
            Swal.fire({
                title: "Êtes vous sûr ?",
                text: "Ce choix est irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, le supprimer !",
                cancelButtonText: "Non, j'ai changé d'avis",
            }).then((result) => {
                if (result.isConfirmed) {

                    try {
                        axios.delete(`https://arolenergy-05673c117bbd.herokuapp.com/groups/${deletedGroup.id}`, {
                            
                        })
                        .then(response => {
                            console.log(response.data.message);
                            this.message = response.data.message;
                            // Créé un nouveau tableau qui ne contient pas l'élément
                            this.groups = this.groups.filter(group => group.id !== deletedGroup.id);
                            this.loadGroups(true);
                        })
                        .catch(error => {
                            console.log(error);
                            this.message = error.data.message;
                            this.showAlertPositive();
                        });
                        
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
                        throw error;
                    }


                    


                    Swal.fire({
                    title: "Groupe supprimé !",
                    text: "Le groupe a été correctement supprimé.",
                    icon: "success"
                    });
                }
            });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.groups = [];
            this.originalGroups = [];
            this.params = { limit: 6, offset: 0, query: '' };
            this.hasMoreGroups = true;
            this.message = '';
        },
    },
})