import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';


export const useChaudieresStore = defineStore("chaudieres", {
    state() {
        return {
            chaudieres: [],
            originalChaudieres: [],
            params: {
                limit: 6,
                offset: 0,
                query: ''
            },
            hasMoreChaudieres: true,
            message: ''
        }
    },
    actions: {
        addChaudiere(newChaudiere) {
            this.chaudieres.unshift(newChaudiere);
            this.loadChaudieres(true);
            console.log(this.hasMoreChaudieres);
        },
        async loadChaudieres(reset = false) {  // Ensure a closing parenthesis here
    
            // Réinitialiser la liste si nécessaire
            if (reset) {
                this.chaudieres = [];
                this.params.offset = 0;
            }
            if (this.params.query) {
                this.chaudieres = [];
                this.params.limit = 6;
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/chaudieres', {
               
            })
            .then(response => {
                const chaudieres = response.data;
                this.originalChaudieres = [...chaudieres]; // Assigne tous les utilisateurs récupérés à `this.chaudieres`
            
                // Met à jour `this.hasMoreChaudieres` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (chaudieres.length > this.params.limit) {
                    this.hasMoreChaudieres = true;
                } else {
                    this.hasMoreChaudieres = false;
                }
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreChaudieres = false; // Assume no more chaudieres if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/chaudieres', {
                params: this.params,  
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newChaudieres = response.data;
                    this.chaudieres.push(...newChaudieres);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreChaudieres = false;
                    }
                } else {
                    this.hasMoreChaudieres = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreChaudieres = false;  // Assume no more chaudieres if there's an error
            });
        },

        async updateChaudiere(chaudiereData) {
    
            try {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/chaudieres/${chaudiereData.id}`, chaudiereData, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.message = error.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'utilisateur :', error);
                throw error;
            }
        },
        async deleteChaudiere(deletedChaudiere) {
    
            Swal.fire({
                title: "Êtes vous sûr ?",
                text: "Ce choix est irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, le supprimer !",
                cancelButtonText: "Non, j'ai changé d'avis",
            }).then((result) => {
                if (result.isConfirmed) {

                    try {
                        axios.delete(`https://arolenergy-05673c117bbd.herokuapp.com/chaudieres/${deletedChaudiere.id}`, {
                            
                        })
                        .then(response => {
                            console.log(response.data.message);
                            this.message = response.data.message;
                            // Créé un nouveau tableau qui ne contient pas l'élément
                            this.chaudieres = this.chaudieres.filter(chaudiere => chaudiere.id !== deletedChaudiere.id);
                            this.loadChaudieres(true);
                        })
                        .catch(error => {
                            console.log(error);
                            this.message = error.data.message;
                            this.showAlertPositive();
                        });
                        
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour du produit :', error);
                        throw error;
                    }


                    Swal.fire({
                    title: "Produit supprimé !",
                    text: "Le produit a été correctement supprimé.",
                    icon: "success"
                    });
                }
            });
        },

        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.chaudieres = [];
            this.originalChaudieres = [];
            this.params = { limit: 6, offset: 0, query: '' };
            this.hasMoreChaudieres = true;
            this.message = '';
        },
    },
})