<template>
    <div class="userList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('user_list.title') }}</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('user_list.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            <table class="table" v-if="userStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 14%;" scope="col">{{ $t('user_list.table.name') }}</th>
                        <th style="width: 14%;" scope="col">{{ $t('user_list.table.first_name') }}</th>
                        <th style="width: 34%;" scope="col">{{ $t('user_list.table.email') }}</th>
                        <th style="width: 34%;" scope="col">{{ $t('user_list.table.telephone') }}</th>
                        <th style="width: 34%;" scope="col">{{ $t('user_list.table.company') }}</th>
                        <th style="width: 21%;" scope="col">{{ $t('user_list.table.role') }}</th>
                        <th style="width: 16.5%;" scope="col">{{ $t('user_list.table.group') }}</th>
                        <th style="width: 16.5%;" scope="col">{{ $t('user_list.table.contact_person') }}</th>
                        <th style="width: 16.5%;" scope="col">{{ $t('user_list.table.rgpd') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in userStore" :key="user.id">
                        <td>
                            <template v-if="userEditing === user.id">
                                <input v-model="user.nom" @keyup.enter="updateUser">
                            </template>
                            <template v-else>
                                {{ user.nom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <input v-model="user.prenom" @keyup.enter="updateUser">
                            </template>
                            <template v-else>
                                {{ user.prenom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <input v-model="user.email" @keyup.enter="updateUser">
                            </template>
                            <template v-else>
                                {{ user.email }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <input v-model="user.telephone" @keyup.enter="updateUser">
                            </template>
                            <template v-else>
                                {{ user.telephone }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <input v-model="user.societe" @keyup.enter="updateUser">
                            </template>
                            <template v-else>
                                {{ user.societe }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <select class="form-select" id="SelectGroupe" required v-model="user.role" @keyup.enter="updateUser">
                                    <option value="">{{ $t('user_list.select_role') }}</option>
                                    <option value="admin">{{ $t('user_list.role_options.admin') }}</option>
                                    <option value="client">{{ $t('user_list.role_options.client') }}</option>
                                </select>
                            </template>
                            <template v-else>
                                {{ user.role }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <select class="form-select" id="SelectGroupe" required v-model="user.groupe" @keyup.enter="updateUser">
                                    <option value="">{{ $t('user_list.select_group') }}</option>
                                    <option v-for="group in groups" :key="group.id" :value="group.nom">{{ group.nom }}</option>
                                </select>
                            </template>
                            <template v-else>
                                {{ user.groupe }}
                            </template>
                        </td>
                        <td>
                            <template v-if="userEditing === user.id">
                                <select class="form-select" id="SelectInterlocuteur" required v-model="user.interlocuteur" @keyup.enter="updateUser">
                                    <option value="">{{ $t('user_list.select_contact_person') }}</option>
                                    <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ contact.nom }} {{ contact.prenom }}</option>
                                </select>
                            </template>
                            <template v-else>
                                {{ getContactName(user.interlocuteur) }}
                            </template>
                        </td>
                        <td>
                            {{ user.rgpd ? 'Accepté' : 'Non accepté' }}
                        </td>
                        <td style="width: 1.5%;"><i v-if="userEditing === user.id" @click="updateUser" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(user.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteUser(user.id)" class="bi bi-trash3-fill"></i></td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('user_list.no_users') }}</div>
            <button class="btn btn-primary" @click="loadUsers" v-if="hasMoreUsers">{{ $t('user_list.show_more_button') }}</button>
        </div>
    </div>
</template>

<script>

import axios from '@/axiosConfig.js';

import { useUsersStore } from '@/store/users.store';

export default {
    data() {
        return {
            users: [],
            message: '',
            userEditing: null,
            searchQuery: '',
            groups: [],
            contacts: [],
        };
    },
    computed: {
        userStore() {
            const usersStore = useUsersStore();
            return usersStore.users;
        },
     
        hasMoreUsers() {
            const usersStore = useUsersStore();
            return usersStore.hasMoreUsers;
        }
    },
    mounted() {
        if (this.userStore.length === 0) {
            this.loadUsers(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
        this.loadGroups(); // Charger les utilisateurs seulement s'il n'y en a pas dÉJÀ
        this.loadContacts(); 
    },
    methods: {
        updateUser() {

            const editedUser = this.userStore.find(user => user.id === this.userEditing);
            if (!editedUser) {
                console.error("Utilisateur non trouvé");
                return;
            }            

            const usersStore = useUsersStore();
            usersStore.updateUser(editedUser);

            this.userEditing = null; // Fin de l'édition
            
        },   
        loadUsers() {
            const usersStore = useUsersStore();
            usersStore.loadUsers();
        },
        
        loadGroups() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/groups', {
               
            })
            .then(response => {
                const groups = response.data;
                this.groups = [...groups]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },

        loadContacts() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/contacts', {
               
            })
            .then(response => {
                const contacts = response.data;
                this.contacts = [...contacts]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },

        getContactName(contactId) {
            const contact = this.contacts.find(contact => contact.id == contactId);
            if (contact) {
                return `${contact.nom} ${contact.prenom}`;
            } else {
                return ""; // Ou tout autre gestion d'erreur
            }
        },

        startEditing(userId) {
            this.userEditing = userId; // Commencer l'édition de l'utilisateur
        },

        deleteUser(userDeleting) {

            const deletedUser = this.userStore.find(user => user.id === userDeleting);

            const usersStore = useUsersStore();
            usersStore.deleteUser(deletedUser);
            
        },
        handleSearch() {
            const usersStore = useUsersStore();
            usersStore.params.query = this.searchQuery;
            usersStore.loadUsers(true); // Reset and load offers based on the search query
        },


    },
    beforeUnmount() {
        const usersStore = useUsersStore();
        usersStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    table {
        display: inline-block;
    }
</style>