<script setup>
  import ConfigurateurForm from '@/components/configurateur/ConfigurateurForm.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="header">
        <h1 v-html="$t('configurations.configurator_title')"></h1>
      </div>
      <ConfigurateurForm/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .header {
    margin-top: 75px;
    h1 {
      margin-bottom: 40px!important;
    }
    .intro {
      width: 45%;
      margin: auto;
      margin-bottom: 40px;
      @media screen and (max-width:1499px) {
        margin: auto;
        margin-bottom: 25px!important; 
        width: 100%!important;
      }
     
    }
    .navConfig {
      margin-bottom: 40px;
      @media screen and (max-width:1499px) {
        display: none!important;
      }
      ul {
        display: flex;
        list-style: none;
        padding: 0px;
        margin: 0px;
        gap: 100px;
        justify-content: center;
        li {
          a {
            position: relative;
            background: #30A29C;
            border-radius: 10px;
            aspect-ratio: 1 / 1;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
            text-decoration: none;
            color: #fff;
            opacity: 0.2;
            &::after {
              content: '';
              height: 1px;
              width: 100px;
              position: absolute;
              background: #30A29C;
              left: 100%;
              opacity: 0.2;
              z-index: -1;
            }
          }
          .router-link-active {
            opacity: 100;
          }
        }
        .config4 a {
          &::after {
            display: none;
          }
        }
      }
   
    }
  }
</style>

