import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';


export const useProductsStore = defineStore("products", {
    state() {
        return {
            products: [],
            originalProducts: [],
            datas : {
                deb_hum_max: 0,
                deb_hum_max_evo: 0,
            },
            params: {
                limit: 6,
                offset: 0,
                query: ''
            },
            hasMoreProducts: true,
            message: ''
        }
    },
    actions: {
        addProduct(newProduct) {
            this.products.unshift(newProduct);
            this.loadProducts(true);
            console.log(this.hasMoreProducts);
        },
        async loadProducts(reset = false) {  // Ensure a closing parenthesis here
    
            // Réinitialiser la liste si nécessaire
            if (reset) {
                this.products = [];
                this.params.offset = 0;
            }
            if (this.params.query) {
                this.products = [];
                this.params.limit = 6;
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/products', {
               
            })
            .then(response => {
                const products = response.data;
                this.originalProducts = [...products]; // Assigne tous les utilisateurs récupérés à `this.products`
            
                // Met à jour `this.hasMoreProducts` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (products.length > this.params.limit) {
                    this.hasMoreProducts = true;
                } else {
                    this.hasMoreProducts = false;
                }
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreProducts = false; // Assume no more products if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/products', {
                params: this.params,  
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newproducts = response.data;
                    this.products.push(...newproducts);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreProducts = false;
                    }
                } else {
                    this.hasMoreProducts = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreProducts = false;  // Assume no more products if there's an error
            });
        },

        async loadAvailableConfig() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/products/configs', {
                params: this.datas,  
               
            }) 
        },
        
        async updateProduct(productData) {
            try {
                const formData = new FormData();
                for (let key in productData) {
                    if (key !== '_fileToUpload') {
                        formData.append(key, productData[key]);
                    }
                }
        
                // Ajouter l'image au formData si elle existe
                if (productData._fileToUpload) {
                    formData.append(productData._fileToUpload.field, productData._fileToUpload.file);
                }
        
                await axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/products/${productData.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour du produit :', error);
                throw error;
            }
        },
        async deleteProduct(deletedProduct) {
    
            Swal.fire({
                title: "Êtes vous sûr ?",
                text: "Ce choix est irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, le supprimer !",
                cancelButtonText: "Non, j'ai changé d'avis",
            }).then((result) => {
                if (result.isConfirmed) {

                    try {
                        axios.delete(`https://arolenergy-05673c117bbd.herokuapp.com/products/${deletedProduct.id}`, {
                            
                        })
                        .then(response => {
                            console.log(response.data.message);
                            this.message = response.data.message;
                            // Créé un nouveau tableau qui ne contient pas l'élément
                            this.products = this.products.filter(product => product.id !== deletedProduct.id);
                            this.loadProducts(true);
                        })
                        .catch(error => {
                            console.log(error);
                            this.message = error.data.message;
                            this.showAlertPositive();
                        });
                        
                    } catch (error) {
                        console.error('Erreur lors de la mise à jour du produit :', error);
                        throw error;
                    }


                    Swal.fire({
                    title: "Produit supprimé !",
                    text: "Le produit a été correctement supprimé.",
                    icon: "success"
                    });
                }
            });
        },

        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.products = [];
            this.originalProducts = [];
            this.params = { limit: 6, offset: 0, query: '' };
            this.hasMoreProducts = true;
            this.message = '';
        },
        setDebHumMax(value) {
            this.datas.deb_hum_max = value;
        },
        setDebHumMaxEvo(value) {
            this.datas.deb_hum_max_evo = value;
        },
    },
})