<script setup>
import { onMounted, computed } from 'vue';
import { useAuthStore } from '@/store/auth.store';
import UserListAdmin from '@/components/utilisateurs/UserListAdmin.vue';
import UserNumberCard from '@/components/utilisateurs/UserNumberCard.vue';
import GroupNumberCard from '@/components/groupes/GroupNumberCard.vue';
import ProductNumberCard from '@/components/produits/ProductNumberCard.vue';

const authStore = useAuthStore();

onMounted(() => {
  if (!authStore.isLoggedIn) {
    authStore.fetchUserDetails();
  }
});

const user = computed(() => authStore.user);
</script>

<template>
  <div>
    <div class="row" v-if="user.nom && user.prenom">
      <div class="col-lg-9">
        <div class="description primary-bg mb-3">
          <h1>{{ $t('welcome_admin.hello', { userPrenom: user.prenom }) }}</h1>
          <p class="content">{{ $t('welcome_admin.message') }}</p>
          <router-link class="btn-secondary" to="/produits-equipements">
            <i class="bi bi-box-seam-fill"></i> {{ $t('welcome_admin.products_equipments_button') }}
          </router-link>
          <button class="btn-secondary right" @click="informClients">
            <i class="bi bi-megaphone-fill"></i> {{ $t('welcome_admin.inform_clients_button') }}
          </button>     
        </div>
        <UserListAdmin/>
      </div>
      <div class="col-lg-3">
        <UserNumberCard />
        <GroupNumberCard />
        <ProductNumberCard />
        <div class="groupes black-bg mb-3">
          <h2>{{ $t('welcome_admin.user_offers.title') }}</h2>
          <p class="content">{{ $t('welcome_admin.user_offers.description') }}</p>
          <router-link class="btn-primary" to="/offres-utilisateurs">
            <i class="bi bi-list-stars"></i> {{ $t('welcome_admin.user_offers.view_offers_button') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from '@/axiosConfig.js';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import Quill from 'quill';
import * as Emoji from 'quill-emoji';
import DOMPurify from 'dompurify';

// Enregistrement du module emoji
Quill.register("modules/emoji", Emoji);

export default {
  data() {
    return {
      quillEditor: null,
      quillContent: '',
    };
  },
  methods: {
    async informClients() {
      const { value: message } = await Swal.fire({
        html: '<div id="quillEditor" style="height: 300px;"></div>',
        showCancelButton: true,
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler',
        didOpen: () => {
          this.initQuill();
        },
        preConfirm: () => {
          this.quillContent = this.quillEditor.root.innerHTML;
          const sanitizedContent = DOMPurify.sanitize(this.quillContent);
          return sanitizedContent;
        }
      });

      if (message) {
        try {
          const response = await axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users/message', { message });
          if (response.status === 201) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Le message a été transmis aux utilisateurs",
              confirmButtonText: "Ok",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } catch (error) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Une erreur est survenue",
            text: "Le message n'a pas pu être envoyé.",
            confirmButtonText: "Ok",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },
    initQuill() {
      const toolbarOptions = {
        container: [
          [{ 'header': [3, 4, 5, false] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['emoji'],  // Ajout du bouton emoji
        ],
        handlers: {'emoji': function() {}}
      };

      this.quillEditor = new Quill('#quillEditor', {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
          "emoji-toolbar": true,      // Activation de la barre d'outils emoji
          "emoji-textarea": true,     // Activation de l'insertion d'emoji dans la textarea
          "emoji-shortname": true,    // Activation de l'insertion via des shortnames (:smile:)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  $primary: #30A29C;

  .description {
    .btn-secondary {
      margin-top: 15px;
    }
    .btn-secondary.right {
      margin-left: 15px;
    }
    h1 {
      color: #fff;
    }
    p.content {
      margin: 0px;
      padding-right: 380px;
      color: #fff;
      @media screen and (max-width:992px) {
        padding: 0px;
      }
    }
  }
  .groupes {
    margin-top: 15px;
    padding-top: 65px;
  }
  .textarea-emoji-control {
      display: none!important;
  }
</style>


