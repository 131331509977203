<template>
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>{{ $t('add_product.title') }}</h2>
        </div>
        <div class="body">
            <div class="mb-3">
                <label for="InputNom" class="form-label">{{ $t('add_product.form.name') }}</label>
                <input type="text" v-model="product.equip" :placeholder="$t('add_product.form.name')" class="form-control" id="InputNom" required>
            </div>
            <div class="mb-3">
                <label for="InputNomCommercial" class="form-label">{{ $t('add_product.form.commercial_name') }}</label>
                <input type="text" v-model="product.equip_commercial" :placeholder="$t('add_product.form.commercial_name')" class="form-control" id="InputNomCommercial" required>
            </div>
            <div class="mb-3">
                <label for="InputDebitMini" class="form-label">{{ $t('add_product.form.min_raw_biogas_flow') }}</label>
                <input type="number" v-model="product.debit_mini_biogaz_brut_equip" :placeholder="$t('add_product.form.min_raw_biogas_flow')" class="form-control" id="InputDebitMini" required>
            </div>
            <div class="mb-3">
                <label for="InputDebitMax" class="form-label">{{ $t('add_product.form.max_raw_biogas_flow') }}</label>
                <input type="number" v-model="product.debit_max_biogaz_brut_equip" :placeholder="$t('add_product.form.max_raw_biogas_flow')" class="form-control" id="InputDebitMax" required>
            </div>

            <div class="mb-3">
                <label for="InputPreTraitement" class="form-label">{{ $t('add_product.form.pre_treatment_capacity') }}</label>
                <input type="text" v-model="product.pre_traitement_capacite" :placeholder="$t('add_product.form.pre_treatment_capacity')" class="form-control" id="InputPreTraitement" required>
            </div>
            <div class="mb-3">
                <label for="InputPrixCharbons" class="form-label">{{ $t('add_product.form.activated_carbon_price') }}</label>
                <input type="number" v-model="product.prix_charbons_actifs" :placeholder="$t('add_product.form.activated_carbon_price')" class="form-control" id="InputPrixCharbons" required>
            </div>
            <div class="mb-3">
                <label for="InputPuissanceCompresseur" class="form-label">{{ $t('add_product.form.compressor_power') }}</label>
                <input type="text" v-model="product.puissance_compresseur" :placeholder="$t('add_product.form.compressor_power')" class="form-control" id="InputPuissanceCompresseur" required>
            </div>
            <div class="mb-3">
                <label for="InputLocalisationCompresseur" class="form-label">{{ $t('add_product.form.compressor_location') }}</label>
                <input type="text" v-model="product.localisation_compresseur" :placeholder="$t('add_product.form.compressor_location')" class="form-control" id="InputLocalisationCompresseur" required>
            </div>
            <div class="mb-3">
                <label for="InputDimensions" class="form-label">{{ $t('add_product.form.dimension') }}</label>
                <input type="text" v-model="product.dimension" :placeholder="$t('add_product.form.dimension')" class="form-control" id="InputDimensions" required>
            </div>

            <div class="mb-3">
                <label for="InputPhotoSansChaudiere" class="form-label">{{ $t('add_product.form.photo_without_boiler') }}</label>
                <input type="file" @change="onFileChange($event, 'photo_sans_chaudiere')" class="form-control" id="InputPhotoSansChaudiere" accept="image/*" required>
            </div>
            <div class="mb-3">
                <label for="InputPhotoAvecChaudiere" class="form-label">{{ $t('add_product.form.photo_with_boiler') }}</label>
                <input type="file" @change="onFileChange($event, 'photo_avec_chaudiere')" class="form-control" id="InputPhotoAvecChaudiere" accept="image/*" required>
            </div>

            <button type="submit" class="btn btn-primary">{{ $t('add_product.form.submit_button') }}</button>
        </div>
    </form>
</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useProductsStore } from '@/store/products.store';

export default {
    data() {
        return {
            product: {
                equip: '',
                equip_commercial: '',
                debit_max_biogaz_brut_equip: '',
                debit_mini_biogaz_brut_equip: '',
                pre_traitement_capacite: '',
                prix_charbons_actifs: 0,
                puissance_compresseur: '',
                localisation_compresseur: '',
                dimension: '',
                photo_sans_chaudiere: null,
                photo_avec_chaudiere: null 
            },
            message: '',
        };
    },

    methods: {
        onFileChange(event, field) {
            const file = event.target.files[0];
            if (file) {
                this.product[field] = file;
            }
        },
        
        submitForm() {
            const formData = new FormData();
            for (let key in this.product) {
                formData.append(key, this.product[key]);
            }

            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/products', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedProduct = {
                        ...this.product,
                        id: response.data.id
                    };
                    const productsStore = useProductsStore();
                    productsStore.addProduct(addedProduct);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.product = {
                        equip: '',
                        equip_commercial: '',
                        debit_max_biogaz_brut_equip: '',
                        debit_mini_biogaz_brut_equip: '',
                        pre_traitement_capacite: '',
                        prix_charbons_actifs: 0,
                        puissance_compresseur: '',
                        localisation_compresseur: '',
                        dimension: '',
                        photo_sans_chaudiere: null,
                        photo_avec_chaudiere: null
                    };
                });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>