<script setup>
  import VariationsListe from '@/components/produits/VariationsListe.vue';
  import AddVariation from '@/components/produits/AddVariation.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-7">
      <VariationsListe/>
    </div>
    <div class="col-lg-5">
      <AddVariation/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .entete {
    text-align: left;
  }
</style>

