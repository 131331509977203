<template>
    
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>Ajouter une variation de produit</h2>
        </div>
        <div class="body">
            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="maxDebitInput" class="form-label">Débit max biogaz brut membrane (Nm³/h)</label>
                    <input type="number" v-model="variation.debit_max_biogaz_brut_membrane" class="form-control" id="maxDebitInput" required>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="maxDebitInput" class="form-label">Prix de l'équipement</label>
                    <input type="number" v-model="variation.prix_equipement" class="form-control" id="maxDebitInput" required>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-lg-12">
                    <label for="maxDebitInput" class="form-label">Commentaire</label>
                    <textarea  v-model="variation.commentaires" class="form-control" id="maxDebitInput" rows="6"></textarea>
                </div>
            </div>

            <button type="submit" class="btn btn-primary">Ajouter la variation</button>
        </div>
    </form>

</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useVariationsStore } from '@/store/variations.store';

export default {
    data() {
        return {
            variation: {
                equip: null,
                debit_max_biogaz_brut_membrane: null,
                prix_equipement: null,
                commentaires: null,
            },
            message: '',
        };
    },
    mounted() {
        const equip = this.$route.params.equip;
        this.variation.equip = equip;
    },
    methods: {
        submitForm() {  // Ensure a closing parenthesis here
    
            
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/variations', this.variation, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedVariation = {
                        ...this.variation,
                        id: response.data.id  // Ajoutez l'`id` à l'objet user
                    };
                    const variationsStore = useVariationsStore();
                    variationsStore.addVariation(addedVariation);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.variation.debit_max_biogaz_brut_membrane = '';
                    this.variation.prix_equipement = '';
                    this.variation.commentaires = '';
                });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>