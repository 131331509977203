import { defineStore } from "pinia";
import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';


export const useVariablesStore = defineStore("variables", {
    state() {
        return {
            variables: [],
            originalVariables: [],
            params: {
                limit: 6,
                offset: 0,
                query: '',
            },
            hasMoreVariables: true,
            message: ''
        }
    },
    actions: {
        async loadVariables(reset = false) {  // Ensure a closing parenthesis here
    
            // Réinitialiser la liste si nécessaire
            if (reset) {
                this.variables = [];
                this.params.offset = 0;
            }
            if (this.params.query) {
                this.variables = [];
                this.params.limit = 6;
                this.params.offset = 0;
            }

            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/variables', {
               
            })
            .then(response => {
                const variables = response.data;
                this.originalVariables = [...variables]; // Assigne tous les utilisateurs récupérés à `this.variables`
            
                // Met à jour `this.hasMoreVariables` pour déterminer s'il faut afficher le bouton "Afficher plus"
                if (variables.length > this.params.limit) {
                    this.hasMoreVariables = true;
                } else {
                    this.hasMoreVariables = false;
                }
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreVariables = false; // Assume no more products if there's an error
            });



            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/variables', {
                params: this.params,
               
            }) 
            .then(response => {
                if (response.data.length > 0) {
                    const newVariables = response.data;
                    this.variables.push(...newVariables);
                    this.params.offset += this.params.limit;
                    if (response.data.length < this.params.limit) {
                        // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
                        this.hasMoreVariables = false;
                    }
                } else {
                    this.hasMoreVariables = false;
                }

            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
                this.hasMoreVariables = false;  // Assume no more products if there's an error
            });
        },

        async updateVariable(variableData) {
    
            try {
                axios.put(`https://arolenergy-05673c117bbd.herokuapp.com/variables/${variableData.id}`, variableData, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.log(error);
                    this.message = error.data.message;
                    this.showAlertNegative();
                });
                
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la variable :', error);
                throw error;
            }
        },
        

        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        resetState() {
            // Réinitialiser l'état
            this.variables = [];
            this.originalVariables = [];
            this.params = { limit: 6, offset: 0, query: ''};
            this.hasMoreVariables = true;
            this.message = '';
        },
    },
})