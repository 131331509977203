<template>
    <div class="productList text-start mb-3 white-bg">
        <div class="header">
            <h2>Liste des chaudières</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" placeholder="Rechercher une chaudière..." @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            
            <table class="table" v-if="chaudiereStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 40%;" scope="col">Puissance</th>
                        <th style="width: 20%;" scope="col">Prix</th>
                        <th style="width: 20%;" scope="col">Prix du bruleur mixte</th>
                        <!-- Add more columns as needed -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="chaudiere in chaudiereStore" :key="chaudiere.id">
                        <td>
                            <template v-if="chaudiereEditing === chaudiere.id">
                                <input v-model="chaudiere.puissance" @keyup.enter="updateChaudiere">
                            </template>
                            <template v-else>
                                {{ chaudiere.puissance }} kWth
                            </template>
                        </td>
                        <td>
                            <template v-if="chaudiereEditing === chaudiere.id">
                                <input type="number" v-model="chaudiere.prix" @keyup.enter="updateChaudiere">
                            </template>
                            <template v-else>
                                {{ chaudiere.prix }} €
                            </template>
                        </td>
                        <td>
                            <template v-if="chaudiereEditing === chaudiere.id">
                                <input type="number" v-model="chaudiere.prix_bruleur_mixte" @keyup.enter="updateChaudiere">
                            </template>
                            <template v-else>
                                {{ chaudiere.prix_bruleur_mixte }} €
                            </template>
                        </td>
                        <td style="width: 1.5%;"><i v-if="chaudiereEditing === chaudiere.id" @click="updateChaudiere" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(chaudiere.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteChaudiere(chaudiere.id)" class="bi bi-trash3-fill"></i></td>
                        <!-- Display more product data as needed -->
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>Aucune chaudière disponible &#128533;</div>
            <button class="btn btn-primary" @click="loadChaudieres" v-if="hasMoreChaudieres">Afficher plus</button>
        </div>
    </div>
</template>

<script>

import { useChaudieresStore } from '@/store/chaudieres.store';

export default {
    data() {
        return {
            chaudieres: [],
            message: '',
            chaudiereEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        chaudiereStore() {
            const chaudieresStore = useChaudieresStore();
            return chaudieresStore.chaudieres;
        },
     
        hasMoreChaudieres() {
            const chaudieresStore = useChaudieresStore();
            return chaudieresStore.hasMoreChaudieres;
        }
    },
    mounted() {
        if (this.chaudiereStore.length === 0) {
            this.loadChaudieres(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {
        updateChaudiere() {

            const editedChaudiere = this.chaudiereStore.find(chaudiere => chaudiere.id === this.chaudiereEditing);
            if (!editedChaudiere) {
                console.error("Chaudière non trouvé");
                return;
            }            

            const chaudieresStore = useChaudieresStore();
            chaudieresStore.updateChaudiere(editedChaudiere);

            this.chaudiereEditing = null; // Fin de l'édition
            
        },   
        loadChaudieres() {
            const chaudieresStore = useChaudieresStore();
            chaudieresStore.loadChaudieres();
        },


        
        startEditing(chaudiereId) {
            this.chaudiereEditing = chaudiereId; // Commencer l'édition de l'utilisateur
        },

        deleteChaudiere(chaudiereDeleting) {

            const deletedChaudiere = this.chaudiereStore.find(chaudiere => chaudiere.id === chaudiereDeleting);

            const chaudieresStore = useChaudieresStore();
            chaudieresStore.deleteChaudiere(deletedChaudiere);

            
        },
        handleSearch() {
            const chaudieresStore = useChaudieresStore();
            chaudieresStore.params.query = this.searchQuery;
            chaudieresStore.loadChaudieres(true); // Reset and load offers based on the search query
        },


    },
    beforeUnmount() {
        const chaudieresStore = useChaudieresStore();
        chaudieresStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    td {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
</style>