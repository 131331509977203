<template>
    <div class="userNumberCard tertiary-bg">
        <div class="row">
            <div class="col-xl-3">
                <svg id="Calque_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 65"><g id="Calque_1-2"><rect id="Rectangle_12" class="cls-1" width="63" height="65" rx="13" ry="13"/><path id="Icon_awesome-user-edit" class="cls-2" d="m26.72,32.5c3.52,0,6.38-2.86,6.38-6.38,0-3.52-2.86-6.38-6.38-6.38s-6.38,2.86-6.38,6.38h0c0,3.52,2.86,6.38,6.38,6.38h0Zm4.47,1.6h-.83c-2.31,1.06-4.96,1.06-7.27,0h-.83c-3.7,0-6.7,3-6.7,6.7t0,0v2.07c0,1.32,1.07,2.39,2.39,2.39h13.71c-.12-.34-.17-.7-.13-1.06l.34-3.04.06-.55.39-.39,3.85-3.85c-1.26-1.44-3.07-2.26-4.98-2.27h0Zm2.26,7.24l-.34,3.04c-.05.44.27.83.7.88.06,0,.12,0,.18,0l3.04-.34,6.87-6.88-3.57-3.57-6.87,6.87Zm13.66-8.2l-1.89-1.89c-.47-.46-1.22-.46-1.68,0l-1.88,1.89-.2.2,3.58,3.57,2.08-2.08c.46-.47.46-1.22,0-1.69h0Z"/></g></svg>
            </div>
            <div class="col-xl-9">
                <div class="numb">
                    {{ usersNumber }}
                </div>
                <p>{{ $t('stat.registered_users') }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import axios from '@/axiosConfig.js';


export default {
    data() {
        return {
            usersNumber: ''
        };
    },
    mounted() {
        this.getNumber(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
    },
    methods: {
        async getNumber() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/users/number', {
               
            }) 
            .then(response => {
                this.usersNumber = response.data;
            })
            .catch(error => {
                const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
                console.error(errorMessage);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .userNumberCard {
        padding: 24px;
        @media screen and (max-width:1499px) {
            text-align: center;
        }
        p {
            margin: 0px;
        }
        svg {
            @media screen and (max-width:1499px) {
                height: 75px;
            }
            path {
                fill: #fff;
            }
        }
        .numb {
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 800;
            color: #fff;
            margin-bottom: 5px;
            font-style: italic;
        }
    }
    
</style>