<template>
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>{{ $t('add_group_form.title') }}</h2>
        </div>
        <div class="body">
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-6">
                        <label for="InputNom" class="form-label">{{ $t('add_group_form.fields.name') }}</label>
                        <input type="text" v-model="group.nom" :placeholder="$t('add_group_form.fields.name')" class="form-control" id="InputNom" required>
                    </div>
                    <div class="col-lg-6">
                        <label for="InputReduction" class="form-label">{{ $t('add_group_form.fields.discount') }}</label>
                        <input type="text" v-model="group.reduction" :placeholder="$t('add_group_form.fields.discount')" class="form-control" id="InputReduction" required>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">{{ $t('add_group_form.submit_button') }}</button>
        </div>
    </form>
</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useGroupsStore } from '@/store/groups.store';

export default {
    data() {
        return {
            group: {
                nom: '',
                reduction: ''
            },
            message: '',
        };
    },

    methods: {
        submitForm() {  // Ensure a closing parenthesis here
    
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/groups', this.group, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedGroup = {
                        ...this.group,
                        id: response.data.id  // Ajoutez l'`id` à l'objet user
                    };
                    const groupsStore = useGroupsStore();
                    groupsStore.addGroup(addedGroup);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.group.nom = '';
                    this.group.reduction = '';
                });
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>