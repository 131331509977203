<script setup>
  import AccountClientForm from '@/components/utilisateurs/AccountClientForm.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="header">
        <h1>{{ $t('account_client.title') }}</h1>
      </div>
      <AccountClientForm/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>