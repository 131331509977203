import { defineStore } from "pinia";
import axios from "@/axiosConfig.js";
import { useAuthStore } from "@/store/auth.store";
import Swal from "sweetalert2";

export const useConfigurationsStore = defineStore("configurations", {
  state() {
    return {
      configurations: [],
      originalConfigurations: [],
      params: {
        limit: 6,
        offset: 0,
        query: "",
        email: "",
      },
      hasMoreConfigurations: true,
      message: "",
      id_configuration: "",
      deb_hum_max: "",
      deb_sec_max: "",
      valeur_debit_brut: "",
      deb_biomet_nom: "",
      deb_biomet_max: "",
      fourniture: "",
      deb_chaud_sec: "",
      deb_chaud_brut: "",
      deb_BG_hum_max: "",
      deb_hum_max_evo: "",
      percent_hum_max_evo: "",
      deb_biomet_max_evo: "",
      selections: "",
      selectedOptionsListDef: "",
    };
  },
  actions: {
    addConfiguration(newConfiguration) {
      this.configurations.unshift(newConfiguration);
    },
    async loadConfigurations(reset = false) {
      // Ensure a closing parenthesis here
      const authStore = useAuthStore();
      this.params.email = authStore.user.email; // dynamically set email from authStore
      // Réinitialiser la liste si nécessaire
      if (reset) {
        this.configurations = [];
        this.params.offset = 0;
      }

      axios
        .get("https://arolenergy-05673c117bbd.herokuapp.com/configurations", {})
        .then((response) => {
          const configurations = response.data;
          this.originalConfigurations = [...configurations]; // Assigne tous les utilisateurs récupérés à `this.users`

          // Met à jour `this.hasMoreUsers` pour déterminer s'il faut afficher le bouton "Afficher plus"
          if (configurations.length > this.params.limit) {
            this.hasMoreConfigurations = true;
          } else {
            this.hasMoreConfigurations = false;
          }
          console.log(this.hasMoreConfigurations);
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "An unknown error occurred";
          console.error(errorMessage);
          this.hasMoreConfigurations = false; // Assume no more users if there's an error
        });

      axios
        .get("https://arolenergy-05673c117bbd.herokuapp.com/configurations", {
          params: this.params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            const newConfigurations = response.data;
            this.configurations.push(...newConfigurations);
            this.params.offset += this.params.limit;
            if (response.data.length < this.params.limit) {
              // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
              this.hasMoreConfigurations = false;
            }
          } else {
            this.hasMoreConfigurations = false;
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "An unknown error occurred";
          console.error(errorMessage);
          this.hasMoreConfigurations = false; // Assume no more users if there's an error
        });
    },
    async loadConfigurationsValidees(reset = false) {
      // Ensure a closing parenthesis here

      // Ensure a closing parenthesis here
      const authStore = useAuthStore();
      this.params.email = authStore.user.email; // dynamically set email from authStore
      // Réinitialiser la liste si nécessaire
      if (reset) {
        this.configurations = [];
        this.params.offset = 0;
      }

      axios
        .get(
          "https://arolenergy-05673c117bbd.herokuapp.com/configurations/valide",
          {}
        )
        .then((response) => {
          const configurations = response.data;
          this.originalConfigurations = [...configurations]; // Assigne tous les utilisateurs récupérés à `this.users`

          // Met à jour `this.hasMoreUsers` pour déterminer s'il faut afficher le bouton "Afficher plus"
          if (configurations.length > this.params.limit) {
            this.hasMoreConfigurations = true;
          } else {
            this.hasMoreConfigurations = false;
          }
          console.log(this.hasMoreConfigurations);
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "An unknown error occurred";
          console.error(errorMessage);
          this.hasMoreConfigurations = false; // Assume no more users if there's an error
        });

      axios
        .get(
          "https://arolenergy-05673c117bbd.herokuapp.com/configurations/valide",
          {
            params: this.params,
          }
        )
        .then((response) => {
          if (response.data.length > 0) {
            const newConfigurations = response.data;
            this.configurations.push(...newConfigurations);
            this.params.offset += this.params.limit;
            if (response.data.length < this.params.limit) {
              // Si le nombre d'utilisateurs chargés est inférieur à la limite, il n'y a plus d'utilisateurs à charger
              this.hasMoreConfigurations = false;
            }
          } else {
            this.hasMoreConfigurations = false;
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "An unknown error occurred";
          console.error(errorMessage);
          this.hasMoreConfigurations = false; // Assume no more users if there's an error
        });
    },
    async updateConfiguration(configurationData) {
      try {
        axios
          .put(
            `https://arolenergy-05673c117bbd.herokuapp.com/configurations/${configurationData.id}`,
            configurationData,
            {}
          )
          .then((response) => {
            console.log(response.data.message);
            this.message = response.data.message;
            this.deb_hum_max = response.data.deb_hum_max;
            this.deb_sec_max = response.data.deb_sec_max;
            this.valeur_debit_brut = response.data.valeur_debit_brut;
            this.deb_biomet_nom = response.data.deb_biomet_nom;
            this.deb_biomet_max = response.data.deb_biomet_max;
            this.fourniture = response.data.fourniture;
            this.deb_chaud_sec = response.data.deb_chaud_sec;
            this.deb_chaud_brut = response.data.deb_chaud_brut;
            this.deb_BG_hum_max = response.data.deb_BG_hum_max;
            this.deb_hum_max_evo = response.data.deb_hum_max_evo;
            this.percent_hum_max_evo = response.data.percent_hum_max_evo;
            this.deb_biomet_max_evo = response.data.deb_biomet_max_evo;
            this.selections = response.data.selections;
            this.selectedOptionsListDef = response.data.selectedOptionsListDef;
            this.id_configuration = response.data.id_configuration;

            this.showAlertPositive();
          })
          .catch((error) => {
            console.log(error);
            //this.message = error.data.message;
            this.showAlertNegative();
          });
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la configuration :",
          error
        );
        throw error;
      }
    },

    async deleteOffer(deletedOffer) {
      console.log(deletedOffer);

      Swal.fire({
        title: "Êtes vous sûr ?",
        text: "Ce choix est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, le supprimer !",
        cancelButtonText: "Non, j'ai changé d'avis",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios
              .delete(
                `https://arolenergy-05673c117bbd.herokuapp.com/configurations/${deletedOffer.configuration_id}`,
                {}
              )
              .then((response) => {
                console.log(response.data.message);
                this.message = response.data.message;
                // Créé un nouveau tableau qui ne contient pas l'élément
                this.configurations = this.configurations.filter(
                  (configuration) => configuration.id !== deletedOffer.id
                );
                this.loadConfigurations(true);
                this.loadConfigurationsValidees(true);
              })
              .catch((error) => {
                console.log(error);
                this.message = error.data.message;
                this.showAlertPositive();
              });
          } catch (error) {
            console.error("Erreur lors de la mise à jour de l'offre :", error);
            throw error;
          }

          Swal.fire({
            title: "Offre supprimée !",
            text: "L'offre a été correctement supprimée.",
            icon: "success",
          });
        }
      });
    },

    async validateOffer(validatedOffer) {
      console.log(validatedOffer);

      Swal.fire({
        title: "Êtes vous sûr ?",
        text: "Ce choix est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider l'offre !",
        cancelButtonText: "Non, j'ai changé d'avis",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            axios
              .put(
                `https://arolenergy-05673c117bbd.herokuapp.com/configurations/validate/${validatedOffer.configuration_id}`,
                {}
              )
              .then((response) => {
                console.log(response.data.message);
                this.message = response.data.message;
                // Créé un nouveau tableau qui ne contient pas l'élément
                this.configurations = this.configurations.filter(
                  (configuration) => configuration.id !== validatedOffer
                );
                this.loadConfigurations(true);
              })
              .catch((error) => {
                console.log(error);
                this.message = error.data.message;
                this.showAlertPositive();
              });
          } catch (error) {
            console.error("Erreur lors de la mise à jour de l'offre :", error);
            throw error;
          }

          Swal.fire({
            title: "Offre validée !",
            text: "L'offre a été correctement validée, une notification a été envoyée au client",
            icon: "success",
          });
        }
      });
    },

    showAlertPositive() {
      // Use sweetalert2
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "success",
        title: this.message,
      });
    },
    showAlertNegative() {
      // Use sweetalert2
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        title: this.message,
      });
    },
    resetState() {
      // Réinitialiser l'état
      this.configurations = [];
      this.originalConfigurations = [];
      this.params = { limit: 6, offset: 0, query: "" };
      this.hasMoreConfigurations = true;
      this.message = "";
    },
  },
});
