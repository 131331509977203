<template>
    <form class="connexion white-bg" @submit.prevent="isCodeStep ? verifyCode() : submitForm()">
        <div class="header">
            <img class="logo" alt="Vue logo" src="../../assets/logo.svg">
        </div>
        <div class="body">
            <!-- Première étape : Email et mot de passe -->
            <div v-if="!isCodeStep">
                <div class="mb-3">
                    <label for="InputEmail" class="form-label">Adresse email</label>
                    <input type="email" v-model="user.email" placeholder="Entrez votre adresse e-mail" class="form-control" id="InputEmail" required>
                </div>
                <div class="mb-3">
                    <label for="InputPassword" class="form-label">Mot de passe</label>
                    <input type="password" v-model="user.motDePasse" placeholder="Entrez votre mot de passe" class="form-control" id="InputPassword" required>
                </div>
    
                <button type="submit" class="btn btn-primary">Me connecter</button>
            </div>
            
            <!-- Deuxième étape : Code de vérification -->
            <div v-if="isCodeStep">
                <div class="mb-3">
                    <label for="InputCode" class="form-label">Code de vérification</label>
                    <input type="text" v-model="user.code" placeholder="Entrez le code reçu par e-mail" class="form-control" id="InputCode" required>
                </div>

                <button type="submit" class="btn btn-primary">Vérifier le code</button>

                <!-- Bouton pour renvoyer le code -->
                <button type="button" @click="resendCode" class="btn btn-link">Renvoyer le code</button>
            </div>
        </div>
    </form>
</template>

<script>

import axios from '@/axiosConfig.js';
import Swal from 'sweetalert2';
import { useAuthStore } from '@/store/auth.store';

export default {
    data() {
        return {
            user: {
                email: '',
                motDePasse: '',
                code: ''
            },
            isCodeStep: false, // Variable pour savoir si on est à la deuxième étape
            message: ''
        };
    },
    mounted() {
        localStorage.clear();
    },
    methods: {
        async submitForm() {
            try {
                const response = await axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users/login-step-one', { email: this.user.email, motDePasse: this.user.motDePasse }, { withCredentials: true });
                console.log("Code de vérification envoyé", response.data);
                
                // Passer à la deuxième étape (demande du code)
                this.isCodeStep = true;
                this.message = "Un code de vérification a été envoyé à votre e-mail.";
            } catch (error) {
                console.error("Erreur lors de la soumission", error);
                this.message = error.response.data.message || "Une erreur est survenue.";
                this.showAlertNegative();
            }
        },
        async verifyCode() {
            try {
                const response = await axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users/verify-code', { email: this.user.email, code: this.user.code }, { withCredentials: true });
                console.log("Connexion réussie", response.data);
                
                localStorage.setItem('tokenExpiry', response.data.expiresIn.toString());
                localStorage.setItem('token', response.data.token);
                
                // Appeler la méthode pour récupérer les informations utilisateur après la connexion
                const authStore = useAuthStore();
                await authStore.fetchUserDetails();
                this.redirectUser(authStore.user.role);
            } catch (error) {
                console.error("Erreur lors de la vérification du code", error);
                this.message = error.response.data.message || "Code de vérification incorrect.";
                this.showAlertNegative();
            } finally {
                this.user.code = ''; // Réinitialiser le champ code
            }
        },

        // Méthode pour renvoyer le code
        async resendCode() {
            try {
                const response = await axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users/resend-code', { email: this.user.email }, { withCredentials: true });
                console.log(response);
                Swal.fire({
                    icon: 'success',
                    title: 'Code renvoyé avec succès',
                    text: 'Veuillez vérifier votre boîte e-mail pour le nouveau code.'
                });
            } catch (error) {
                this.showError(error);
            }
        },
        showAlertNegative() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: this.message
            });
        },
        redirectUser(role) {
            // Redirection basée sur le rôle
            if (role === 'admin') {
                this.$router.push('/tableau-de-bord-administrateur');
            } else {
                this.$router.push('/tableau-de-bord-client');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */
.logo {
    margin-bottom: 20px;
    height: 150px;
    @media screen and (max-width:1499px) {
        height: 115px;
    }
}

button.btn.btn-link {
    color: #30A29C;
}



form {
    max-width: 40%;
    width: 100%;
    padding: 35px 70px;
    @media screen and (max-width:1499px) {
        max-width: 95%;
        padding: 25px 15px;
    }
    &::after {
        content: '';
        background: #F78444;
        position: absolute;
        height: 35%;
        width: 50%;
        top: 50%;
        left: 100%;
        transform: translate(-100%,-50%);
        z-index: -1;
    }
    &::before {
        content: '';
        background: #285F7B;
        position: absolute;
        height: 35%;
        width: 50%;
        top: 50%;
        left: 0%;
        transform: translate(0%,-50%);
        z-index: -1;
    }
    .body {
        @media screen and (max-width:1499px) {
            padding: 0px 10px!important;
        }
    }
    .header {
        justify-content: center;
        align-items: center;
    }
}
</style>