import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createI18n } from 'vue-i18n'
import fr from './locales/fr.json'
import en from './locales/en.json'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Créer l'instance i18n
const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages: {
        fr,
        en
    }
})

// Créer une seule instance de l'application Vue
const app = createApp(App)

// Ajouter les plugins à cette instance
app.use(i18n)
app.use(createPinia())
app.use(router)

// Monter l'application
app.mount('#app')