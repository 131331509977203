<template>
    <div class="userList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('contact_list.title') }}</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('contact_list.filters.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            <table class="table" v-if="contactStore.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{ $t('contact_list.table.columns.name') }}</th>
                        <th scope="col">{{ $t('contact_list.table.columns.surname') }}</th>
                        <th scope="col">{{ $t('contact_list.table.columns.email') }}</th>
                        <th scope="col">{{ $t('contact_list.table.columns.phone') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="contact in contactStore" :key="contact.id">
                        <td>
                            <template v-if="contactEditing === contact.id">
                                <input v-model="contact.nom" @keyup.enter="updateContact">
                            </template>
                            <template v-else>
                                {{ contact.nom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="contactEditing === contact.id">
                                <input type="text" v-model="contact.prenom" @keyup.enter="updateContact">
                            </template>
                            <template v-else>
                                {{ contact.prenom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="contactEditing === contact.id">
                                <input type="email" v-model="contact.email" @keyup.enter="updateContact">
                            </template>
                            <template v-else>
                                {{ contact.email }}
                            </template>
                        </td>
                        <td>
                            <template v-if="contactEditing === contact.id">
                                <input type="text" v-model="contact.telephone" @keyup.enter="updateContact">
                            </template>
                            <template v-else>
                                {{ contact.telephone }}
                            </template>
                        </td>
                        <td style="width: 1.5%;"><i v-if="contactEditing === contact.id" @click="updateContact" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(contact.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteContact(contact.id)" class="bi bi-trash3-fill"></i></td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('contact_list.table.no_contact_available') }} &#128533;</div>
            <button class="btn btn-primary" @click="loadContacts" v-if="hasMoreContacts">{{ $t('contact_list.buttons.show_more') }}</button>
        </div>
    </div>
</template>

<script>

import { useContactsStore } from '@/store/contacts.store';

export default {
    data() {
        return {
            contacts: [],
            message: '',
            contactEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        contactStore() {
            const contactsStore = useContactsStore();
            return contactsStore.contacts;
        },
     
        hasMoreContacts() {
            const contactsStore = useContactsStore();
            return contactsStore.hasMoreContacts;
        }
    },
    mounted() {
        if (this.contactStore.length === 0) {
            this.loadContacts(); // Charger les groupes seulement s'il n'y en a pas déjà
        }
    },
    methods: {
        updateContact() {

            const editedContact = this.contactStore.find(contact => contact.id === this.contactEditing);
            if (!editedContact) {
                console.error("Interlocuteur non trouvé");
                return;
            }            

            const contactsStore = useContactsStore();
            contactsStore.updateContact(editedContact);

            this.contactEditing = null; // Fin de l'édition

        },   

        loadContacts() {
            const contactsStore = useContactsStore();
            contactsStore.loadContacts();
            
        },
     
        startEditing(contactId) {
            this.contactEditing = contactId; // Commencer l'édition de l'utilisateur
        },

        deleteContact(contactDeleting) {

            const deletedContact = this.contactStore.find(contact => contact.id === contactDeleting);

            const contactsStore = useContactsStore();
            contactsStore.deleteContact(deletedContact);

            
        },
        handleSearch() {
            const contactsStore = useContactsStore();
            contactsStore.params.query = this.searchQuery.trim();
            contactsStore.loadContacts(true); // Reset and load offers based on the search query
        },

    },
    beforeUnmount() {
        const contactsStore = useContactsStore();
        contactsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>

</style>