import { defineStore } from 'pinia';
import router from '@/router';
import Swal from 'sweetalert2';
import axios from '@/axiosConfig.js';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: {
            id: '',
            nom:  '',
            prenom: '',
            role: '',
            groupe: '',
            email: '',
            telephone: '',
            adresse: '',
            interlocuteur_nom: '',
            interlocuteur_prenom: '',
            interlocuteur_id: '',
            societe: '',
            language: '',
            first_time_connection: '',
            rgpd: 0,
        },
        isLoggedIn: false,
        tokenExpiry: null,
    }),
    actions: {
        async updateFirstTimeConnection(rgpdAccepted) {
            try {
                await axios.post(`https://arolenergy-05673c117bbd.herokuapp.com/users/first-time-connection`, {
                    user: this.user.id,
                    rgpd: rgpdAccepted ? true : false,
                    first_time_connection: 1
                });
                this.user.rgpd = rgpdAccepted ? 1 : 0;
            } catch (error) {
                console.error("Erreur lors de la mise à jour de first_time_connection", error);
            }
        },
        async checkInitialLogin() {
            try {
                const response = await axios.get('https://arolenergy-05673c117bbd.herokuapp.com/users/me', {
                });
                this.user = { ...response.data };
                this.isLoggedIn = true;
            } catch (error) {
                console.error("Erreur lors de la vérification du token", error);
                this.isLoggedIn = false;
            }
        },
        async fetchUserDetails() {
            try {
                const response = await axios.get('https://arolenergy-05673c117bbd.herokuapp.com/users/me');
                const userDetails = response.data;
                this.user.id = userDetails.id;
                this.user.nom = userDetails.nom;
                this.user.prenom = userDetails.prenom;
                this.user.role = userDetails.role;
                this.user.groupe = userDetails.groupe;
                this.user.email = userDetails.email;
                this.user.telephone = userDetails.telephone;
                this.user.adresse = userDetails.adresse;
                this.user.interlocuteur_nom = userDetails.interlocuteur_nom;
                this.user.interlocuteur_prenom = userDetails.interlocuteur_prenom;
                this.user.interlocuteur_id = userDetails.interlocuteur_id;
                this.user.societe = userDetails.societe;
                this.user.language = userDetails.language;
                this.first_time_connection = userDetails.first_time_connection;
                this.user.rgpd = userDetails.rgpd;
                this.isLoggedIn = true;
            } catch (error) {
                console.error("Erreur lors de la récupération des informations utilisateur", error);
                if (error.response) {
                    console.error("Réponse serveur:", error.response.data);
                }
            }
        },
            clearUser(isSessionExpired = false) {
                // Supprimer le token du localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiry');
            
                // Réinitialiser les variables d'état de l'utilisateur
                this.tokenExpiry = null;
                this.isLoggedIn = false;
                this.user.id = '';
                this.user.nom = '';
                this.user.prenom = '';
                this.user.role = '';
                this.user.groupe = '';
                this.user.email = '';
                this.user.telephone = '';
                this.user.adresse = '';
                this.user.interlocuteur_nom = '';
                this.user.interlocuteur_prenom = '';
                this.user.interlocuteur_id = '';
                this.user.societe = '';
                this.user.first_time_connection = '';
                this.user.language = '';
                this.user.rgpd = 0;
            
                // Redirection vers la page de connexion
                router.push('/connexion').then(() => {
                    if (isSessionExpired) {
                        Swal.fire({
                            icon: "error",
                            title: "Session expirée, veuillez vous reconnecter!",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Vous avez été déconnecté avec succès.",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                });
            },
          async checkTokenExpiry() {
            const tokenExpiry = parseInt(localStorage.getItem('tokenExpiry'), 10);
            const now = Date.now();

            // Vérifier si le token est expiré
            if (now > tokenExpiry) {
                // Vérifier l'état de connexion initial
                await this.checkInitialLogin();
                
                // Si toujours connecté, déclencher la déconnexion
                if (this.isLoggedIn) {
                    await this.clearUser(true);
                }
            }
        }
    
    }
});