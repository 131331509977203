<script setup>
import ProductList from '@/components/produits/ProductList.vue'
import AddProduct from '@/components/produits/AddProduct.vue';
import BoilerList from '@/components/produits/BoilerList.vue';
import AddBoiler from '@/components/produits/AddBoiler.vue';

</script>

<template>
  <div class="row mb-4">
    <div class="col-lg-7">
      <ProductList/>
    </div>
    <div class="col-lg-5">
      <AddProduct/>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-7">
      <BoilerList/>
    </div>
    <div class="col-lg-5">
      <AddBoiler/>
    </div>
  </div>
  
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>

