<script setup>
import ConfigListBoard from '@/components/configurateur/ConfigListBoard.vue';

</script>

<template>
  <div v-if="user">
    <div class="row mb-4">
      <div class="col-lg-9">
        <div class="description primary-bg mb-3">
          <h1>{{ $t('welcome.hello', { name: user.prenom || 'Client' }) }}</h1>
          <p class="content">{{ $t('welcome.message') }}</p>
          <router-link class="btn-secondary" to="/configurateur"><i class="bi bi-tools"></i>{{ $t('welcome.access_configurator') }}</router-link>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="interlocuteur tertiary-bg">
          <p class="pre-titre">{{ $t('welcome.your_contact') }}</p>
          <h2>
            <span class="nom">{{ user.interlocuteur_nom || $t('welcome.not_available') }}</span>
            <span class="prenom">{{ user.interlocuteur_prenom || $t('welcome.not_available') }}</span>
          </h2>
          <div class="infos">
            <span><i class="bi bi-envelope-at-fill"></i><a :href="'mailto:' + email">{{ email || $t('welcome.not_available') }}</a></span>
            <span><i class="bi bi-telephone-fill"></i><a :href="'tel:' + telephone">{{ telephone || $t('welcome.not_available') }}</a></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="infos mb-4 white-bg">
          <h2>{{ $t('welcome.information') }}</h2>
          <div v-html="message"></div>
        </div>
      </div>
      <div class="col-lg-12">
        <ConfigListBoard />
      </div>
    </div>
  </div>
  <div v-else>
    <p>{{ $t('welcome.loading_user_data') }}</p>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth.store';
import axios from '@/axiosConfig.js';

export default {
  data() {
    return {
      email: '',
      telephone: '',
      message: '',
    }
  },
  mounted() {
    this.getContactInfos(); 
    this.getMessage(); 
  },
  computed: {
    user() {
      const authStore = useAuthStore();
      return authStore.user;
    },

  },
  methods: {
    getContactInfos() {
      if (this.user) {
        const interlocuteur_id = this.user.interlocuteur_id;
        axios.get(`https://arolenergy-05673c117bbd.herokuapp.com/contacts/${interlocuteur_id}`)
        .then(response => {
          const contact = response.data[0];
          this.email = contact.email;
          this.telephone = contact.telephone;
        })
        .catch(error => {
          const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
          console.error(errorMessage);
        });
      } else {
        console.error('Utilisateur non trouvé');
      }
    },
    getMessage() {
      axios.get(`https://arolenergy-05673c117bbd.herokuapp.com/users/message`)
      .then(response => {
        this.message = response.data[0].message;
        console.log("Raw Message: ", this.message);
      })
      .catch(error => {
        const errorMessage = error.response && error.response.data && error.response.data.message ? error.response.data.message : "An unknown error occurred";
        console.error(errorMessage);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
$primary: #30A29C;
.description {
  .btn-secondary {
    margin-top: 15px;
  }
  h1 {
    color: #fff;
  }
  p.content {
    margin: 0px;
    padding-right: 380px;
    color: #fff;
    @media screen and (max-width:992px) {
      padding: 0px;
    }
  }
}
.groupes {
  margin-top: 15px;
}
.interlocuteur {
  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    .nom {
      text-transform: uppercase;
    }
  }
  .pre-titre {
    text-transform: uppercase;
    font-weight: 600!important;
    text-align: center;
    font-size: 0.9rem;
  }
  .infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    i {
      font-size: 1.2rem;
    }
    span {
      display: flex;
      margin: 2px 0px;
    }
  }
}
.description {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.infos {
  text-align: left;
}
</style>


  <style lang="scss" scoped>
    $primary: #30A29C;
    .description {
      .btn-secondary {
        margin-top: 15px;
      }
      h1 {
        color: #fff;
      }
      p.content {
        margin: 0px;
        padding-right: 380px;
        color: #fff;
        @media screen and (max-width:992px) {
          padding: 0px;
        }
      }
    }
    .groupes {
      margin-top: 15px;
    }
    .interlocuteur {
      h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
        .nom {
          text-transform: uppercase;
        }
      }
      .pre-titre {
        text-transform: uppercase;
        font-weight: 600!important;
        text-align: center;
        font-size: 0.9rem;
      }
      .infos {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.9rem;
        i {
          font-size: 1.2rem;
        }
        span {
          display: flex;
          margin: 2px 0px;
        }
      }
    }
    .description {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .infos {
      text-align: left;
    }
  </style>