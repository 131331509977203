<template>
    <form class="white-bg" @submit.prevent="submitForm">
        <div class="header">
            <h2>{{ $t('user_add_form.title') }}</h2>
        </div>
        <div class="body">
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-6">
                        <label for="InputNom" class="form-label">{{ $t('user_add_form.labels.last_name') }}</label>
                        <input type="text" v-model="user.nom" :placeholder="$t('user_add_form.placeholders.last_name')" class="form-control" id="InputNom" required>
                    </div>
                    <div class="col-lg-6">
                        <label for="InputPrenom" class="form-label">{{ $t('user_add_form.labels.first_name') }}</label>
                        <input type="text" v-model="user.prenom" :placeholder="$t('user_add_form.placeholders.first_name')" class="form-control" id="InputPrenom" required>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row">
                    <div class="col-lg-6">
                        <label for="SelectRole" class="form-label">{{ $t('user_add_form.labels.role') }}</label>
                        <select class="form-select" id="SelectRole" required v-model="user.role">
                            <option value="">{{ $t('user_add_form.labels.select_role') }}</option>
                            <option value="admin">{{ $t('user_add_form.labels.role_options.admin') }}</option>
                            <option value="client">{{ $t('user_add_form.labels.role_options.client') }}</option>
                        </select>
                    </div>
                    <div class="col-lg-6">
                        <label for="SelectGroupe" class="form-label">{{ $t('user_add_form.labels.group') }}</label>
                        <select class="form-select" id="SelectGroupe" v-model="user.groupe">
                            <option value="">{{ $t('user_add_form.labels.select_group') }}</option>
                            <option v-for="group in groups" :key="group.id" :value="group.nom">{{ group.nom }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="SelectInterlocuteur" class="form-label">{{ $t('user_add_form.labels.contact_person') }}</label>
                <select class="form-select" id="SelectInterlocuteur" v-model="user.interlocuteur">
                    <option value="">{{ $t('user_add_form.labels.select_contact_person') }}</option>
                    <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ contact.nom }} {{ contact.prenom }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="InputEmail" class="form-label">{{ $t('user_add_form.labels.email') }}</label>
                <input type="email" v-model="user.email" :placeholder="$t('user_add_form.placeholders.email')" class="form-control" id="InputEmail" required>
            </div>
            <div class="mb-3">
                <label for="InputTel" class="form-label">{{ $t('user_add_form.labels.phone') }}</label>
                <input type="tel" v-model="user.telephone" :placeholder="$t('user_add_form.placeholders.phone')" class="form-control" id="InputTel">
            </div>
            <div class="mb-3">
                <label for="InputAdresse" class="form-label">{{ $t('user_add_form.labels.address') }}</label>
                <input type="text" v-model="user.adresse" :placeholder="$t('user_add_form.placeholders.address')" class="form-control" id="InputAdresse">
            </div>
            <div class="mb-3">
                <label for="InputSociete" class="form-label">{{ $t('user_add_form.labels.company') }}</label>
                <input type="text" v-model="user.societe" :placeholder="$t('user_add_form.placeholders.company')" class="form-control" id="InputSociete">
            </div>
            <div class="mb-3">
                <label for="InputPassword" class="form-label">{{ $t('user_add_form.labels.password') }}</label>
                <input type="password" v-model="user.motDePasse" :placeholder="$t('user_add_form.placeholders.password')" class="form-control" id="InputPassword" required>
            </div>

            <button type="submit" class="btn btn-primary">{{ $t('user_add_form.submit_button') }}</button>
        </div>
    </form>
</template>

<script>

import axios from '@/axiosConfig.js';

import Swal from 'sweetalert2';
import { useUsersStore } from '@/store/users.store';

export default {
    data() {
        return {
            user: {
                nom: '',
                prenom: '',
                email: '',
                telephone: '',
                adresse: '',
                role: '',
                motDePasse: '',
                groupe: '',
                interlocuteur: '',
                societe: ''
            },
            groups: [],
            contacts: [],
            message: '',
        };
    },
    mounted() {
        this.loadGroups(); 
        this.loadContacts(); 
    },
    methods: {
        submitForm() {  // Ensure a closing parenthesis here
    
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users', this.user, {
                    
                })
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    const addedUser = {
                        ...this.user,
                        id: response.data.id  // Ajoutez l'`id` à l'objet user
                    };
                    const usersStore = useUsersStore();
                    usersStore.addUser(addedUser);
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response.data.message);
                    this.message = error.response.data.message;
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.user.nom = '';
                    this.user.prenom = '';
                    this.user.email = '';
                    this.user.telephone = '';
                    this.user.adresse = '';
                    this.user.role = '';
                    this.user.motDePasse = '';
                    this.user.groupe = '';
                    this.user.interlocuteur = '';
                    this.user.societe = '';
                });
        },
        loadGroups() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/groups', {
               
            })
            .then(response => {
                const groups = response.data;
                this.groups = [...groups]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },
        loadContacts() {
    
            axios.get('https://arolenergy-05673c117bbd.herokuapp.com/contacts', {
               
            })
            .then(response => {
                const contacts = response.data;
                this.contacts = [...contacts]; // Assigne tous les utilisateurs récupérés à `this.users`
            })
        },
        showAlertPositive() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            // Use sweetalert2
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
                });
                Toast.fire({
                icon: "error",
                title: this.message
            });
        },
    }
}
</script>

<style lang="scss" scoped>
/* Styles pour le composant de connexion */

</style>