
<template>
    <div class="variableList text-start mb-3 white-bg">
        <div class="body">
            <h2>{{ $t('variable_list.title') }}</h2>
            <table class="table" v-if="variableStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 50%;" scope="col">{{ $t('variable_list.table.headers.name') }}</th>
                        <th style="width: 50%;" scope="col">{{ $t('variable_list.table.headers.value') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="variable in variableStore" :key="variable.id">
                        <td>
                            <template v-if="variableEditing === variable.id">
                                <input v-model="variable.nom" @keyup.enter="updateVariable">
                            </template>
                            <template v-else>
                                {{ variable.nom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="variableEditing === variable.id">
                                <input type="number" v-model="variable.valeur" @keyup.enter="updateVariable">
                            </template>
                            <template v-else>
                                {{ variable.valeur }}
                            </template>
                        </td>
                        <td style="width: 1.5%;">
                            <i v-if="variableEditing === variable.id" @click="updateVariable" class="bi bi-check-square-fill"></i>
                            <i v-else @click="startEditing(variable.id)" class="bi bi-pen-fill"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('variable_list.table.no_variables') }}</div>
            <button class="btn btn-primary" @click="loadVariables" v-if="hasMoreVariables">{{ $t('variable_list.buttons.load_more') }}</button>
        </div>
    </div>
</template>

<script>

import { useVariablesStore } from '@/store/variables.store';

export default {
    data() {
        return {
            variables: [],
            message: '',
            variableEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        variableStore() {
            const variablesStore = useVariablesStore();
            return variablesStore.variables;
        },
     
        hasMoreVariables() {
            const variablesStore = useVariablesStore();
            return variablesStore.hasMoreVariables;
        }
    },
    mounted() {
        if (this.variableStore.length === 0) {
            this.loadVariables(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {
        updateVariable() {

            const editedVariable = this.variableStore.find(variable => variable.id === this.variableEditing);
            if (!editedVariable) {
                console.error("Variable non trouvée");
                return;
            }            

            const variablesStore = useVariablesStore();
            variablesStore.updateVariable(editedVariable);

            this.variableEditing = null; // Fin de l'édition
            
        },   
        loadVariables() {
            const variablesStore = useVariablesStore();
            variablesStore.loadVariables();
        },


        
        startEditing(variableId) {
            this.variableEditing = variableId; // Commencer l'édition de l'utilisateur
        },

    },
    beforeUnmount() {
        const variablesStore = useVariablesStore();
        variablesStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    td {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
</style>