<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row">
        <div v-if="isLogged && $route.path !== '/connexion'" class="col-lg-2 app-left">
          <div class="nav-container white-bg">
            <img class="logo-menu" alt="Vue logo" src="./assets/logo.svg">
            <div class="links">
              <nav v-if="user.role == 'admin'" class="">
                <router-link to="/tableau-de-bord-administrateur"><i class="bi bi-speedometer"></i>{{ $t('menu.admin_dashboard') }}</router-link>
                <router-link v-if="!isLogged" to="/connexion">{{ $t('menu.login') }}</router-link>
                <router-link to="/produits-equipements"><i class="bi bi-box-seam-fill"></i>{{ $t('menu.products_equipments') }}</router-link>
                <router-link to="/utilisateurs-groupes"><i class="bi bi-people-fill"></i>{{ $t('menu.users_groups') }}</router-link>
                <router-link to="/offres-utilisateurs"><i class="bi bi-list-stars"></i>{{ $t('menu.users_offers') }}</router-link>
                <router-link to="/variables-options"><i class="bi bi-gear-fill"></i>{{ $t('menu.variables_options') }}</router-link>
              </nav>
              <nav v-if="user.role == 'client'" class="">
                <router-link to="/tableau-de-bord-client"><i class="bi bi-speedometer"></i>{{ $t('menu.client_dashboard') }}</router-link>
                <router-link to="/configurateur"><i class="bi bi-grid-1x2-fill"></i>{{ $t('menu.configurator') }}</router-link>
                <router-link to="/offres-client"><i class="bi bi-list-stars"></i>{{ $t('menu.my_offers') }}</router-link>
                <router-link to="/autres-demandes"><i class="bi bi-chat-text-fill"></i>{{ $t('menu.other_requests') }}</router-link>
              </nav>
              <div class="version">
                {{ $t('menu.version') }} {{ appVersion }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="isLogged && $route.path !== '/connexion'" class="col-lg-10 app-right">
          <div class="deco">



            <div class="language-selector">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="32" 
                height="32" 
                viewBox="0 0 32 32" 
                @click="changeLocale('fr')" 
                class="flag"
              >
                <path fill="#fff" d="M10 4H22V28H10z"></path>
                <path d="M5,4h6V28H5c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z" fill="#092050"></path>
                <path d="M25,4h6V28h-6c-2.208,0-4-1.792-4-4V8c0-2.208,1.792-4,4-4Z" transform="rotate(180 26 16)" fill="#be2a2c"></path>
                <path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path>
                <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path>
              </svg>
              
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="32" 
                height="32" 
                viewBox="0 0 32 32" 
                @click="changeLocale('en')" 
                class="flag"
              >
                <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#071b65"></rect>
                <path d="M5.101,4h-.101c-1.981,0-3.615,1.444-3.933,3.334L26.899,28h.101c1.981,0,3.615-1.444,3.933-3.334L5.101,4Z" fill="#fff"></path>
                <path d="M22.25,19h-2.5l9.934,7.947c.387-.353,.704-.777,.929-1.257l-8.363-6.691Z" fill="#b92932"></path>
                <path d="M1.387,6.309l8.363,6.691h2.5L2.316,5.053c-.387,.353-.704,.777-.929,1.257Z" fill="#b92932"></path>
                <path d="M5,28h.101L30.933,7.334c-.318-1.891-1.952-3.334-3.933-3.334h-.101L1.067,24.666c.318,1.891,1.952,3.334,3.933,3.334Z" fill="#fff"></path>
                <rect x="13" y="4" width="6" height="24" fill="#fff"></rect>
                <rect x="1" y="13" width="30" height="6" fill="#fff"></rect>
                <rect x="14" y="4" width="4" height="24" fill="#b92932"></rect>
                <rect x="14" y="1" width="4" height="30" transform="translate(32) rotate(90)" fill="#b92932"></rect>
                <path d="M28.222,4.21l-9.222,7.376v1.414h.75l9.943-7.94c-.419-.384-.918-.671-1.471-.85Z" fill="#b92932"></path>
                <path d="M2.328,26.957c.414,.374,.904,.656,1.447,.832l9.225-7.38v-1.408h-.75L2.328,26.957Z" fill="#b92932"></path>
                <path d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z" opacity=".15"></path>
                <path d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z" fill="#fff" opacity=".2"></path>
              </svg>
            </div>


            
            <router-link class="account" to="/compte-client"><i class="bi bi-person-fill"></i><span>{{ user.nom }} {{ user.prenom }}</span></router-link>
            <a href="#" class="btn-primary" @click="logout"><i class="bi bi-door-closed-fill"></i>{{ $t('menu.logout') }}</a>
          </div>
          <div :class="{ 'classe-connexion': $route.path === '/connexion' }">
            <router-view />
          </div>
        </div>

        <div v-else class="col-lg-12">
          <div :class="{ 'classe-connexion': $route.path === '/connexion' }">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/store/auth.store';
import packageInfo from '../package.json';

export default {
  data() {
    return {
      isLoggedIn: false,
      appVersion: packageInfo.version,
      currentLocale: 'fr'  // valeur par défaut
    };
  },
  computed: {
    isLogged() {
      const authStore = useAuthStore();
      return authStore.isLoggedIn;
    },
    user() {
      const authStore = useAuthStore();
      return authStore.user;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser.language) {
          console.log(newUser.language);
          this.currentLocale = newUser.language;
          this.changeLocale(this.currentLocale);
        }
      }
    }
  },
  methods: {
    async logout() {
      const authStore = useAuthStore();
      if (authStore.isLoggedIn) {
        await authStore.clearUser();
        // Redirection vers la page de connexion
        localStorage.clear();
        this.$router.push('/connexion');
      } else {
        console.error('Tentative de déconnexion sans être connecté');
      }
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  },
  mounted() {
    
    
    const authStore = useAuthStore();
    // Commencez à vérifier l'expiration du jeton toutes les secondes si l'utilisateur est connecté
    this.interval = setInterval(() => {
      if (authStore.isLoggedIn) {
        authStore.checkTokenExpiry();
      }
    }, 1000); // Toutes les secondes
  },
  beforeUnmount() {
    // Nettoyez l'intervalle pour éviter les fuites de mémoire
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">

$primary: #30A29C;
$secondary: #2F3937;
$tertiary: #FD8543;
$fourth: #285F7B;
$five: #2F3937;
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

body {
  background: #fff;
}

.bi {
  font-size: 1.3rem;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  


}

.btn-primary, button.swal2-confirm.swal2-styled {
  background: $primary!important;
  border-radius: 10px!important;
  color: #fff!important;
  border: 0px!important;
  padding: 15px 30px!important;
  font-weight: 600!important;
  display: inline-block!important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none;
  font-size: 0.9rem!important;
  @media screen and (max-width:1499px) {
    padding: 10px 15px!important;  
  }
}

.btn-secondary, button.swal2-cancel.swal2-styled {
  background: $secondary!important;
  border-radius: 10px!important;
  color: #fff!important;
  border: 0px!important;
  padding: 15px 30px!important;
  font-weight: 600!important;
  display: inline-block!important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-decoration: none;
  font-size: 0.9rem!important;
  @media screen and (max-width:1499px) {
    padding: 10px 15px!important;  
  }
}

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $secondary;
  @media screen and (max-width:1499px) {
    padding-bottom: 100px;
  }
  
  .app-left {
    height: 100vh;
    padding: 0px;
    padding: 15px;
    padding-right: 7.5px;
    position: sticky;
    top: 0;
    @media screen and (max-width:1499px) {
      height: unset !important;
      padding: 0px;
      padding-right: 0px;
      position: fixed;
      bottom: 0;
      width: 100vw;
      top: inherit;
    }
    .nav-container {
      height: 100%;
      padding: 30px 15px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width:1499px) {
        padding: 5px 10px;        
        border-radius: 15px 15px 0px 0px!important;
      }
      .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .version {
          opacity: 0.2;
          font-size: 0.9rem;
          font-weight: 600;
          @media screen and (max-width:1499px) {
            display: none;
          }
        }
      }
      nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        flex-direction: column;
        @media screen and (max-width:1499px) {
          flex-direction: row;
        }
        a {
          font-weight: bold;
          color: #2c3e50;
          margin: 2px 0px;
          width: 100%;
          text-align: left;
          padding: 15px 16px;
          text-decoration: none;
          display: flex;
          align-items: center;
          &.router-link-exact-active {
            background: $primary;
            border-radius: 10px;
            color: #fff;
            
          }
          @media screen and (max-width:1499px) {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 0.45rem!important;
            text-align: center;
            line-height: 0.45rem;
          }
        }
        .bi {
          @media screen and (max-width:1499px) {
            font-size: 1.5rem;
            margin-right: 0px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .logo-menu {
        margin-bottom: 40px;
        width: 90%;
        @media screen and (max-width:1499px) {
          display: none;
        }
      }
    }
    
  }

  .app-right {
    padding: 0px;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 7.5px;
    @media screen and (max-width:1499px) {
      padding: 0px;
      width: 100%;
      padding-top: 15px;
      padding-right: calc(var(--bs-gutter-x)*.5);
      padding-bottom: calc(var(--bs-gutter-x)*.5);
      padding-left: calc(var(--bs-gutter-x)*.5);
    }
    .interlocuteur {
      @media screen and (max-width:1499px) {
        margin-top: 15px;
      }
      
    }
  }
}

h1 {
  font-weight: 700!important;
  margin-bottom: 15px!important;
  font-size: 1.9rem;
  @media screen and (max-width:1499px) {
    font-size: 1.6rem;
  }
}

h2 {
  font-size: 1.5rem;
  @media screen and (max-width:1499px) {
    font-size: 1.3rem;
  }
}

p, label {
  font-weight: 500!important;
  font-size: 0.9rem!important;
  color: $secondary!important;
  @media screen and (max-width:1499px) {
    font-size: 0.85rem!important;
  }
}

a {
  font-weight: 600!important;
  font-size: 0.9rem!important;
  @media screen and (max-width:1499px) {
    font-size: 0.85rem!important;
  }
}

td, li {
  font-weight: 500!important;
  font-size: 0.9rem!important;
  @media screen and (max-width:1499px) {
    font-size: 0.85rem!important;
  }
}

b, strong {
  font-weight: 600;
}

h2, h3, h4 {
  color: $secondary!important;
  font-weight: 700!important;
}

.classe-connexion {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-bg {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.20);
  border-radius: 15px;
  padding: 30px;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
}

.note {
  margin: 0;
  margin-top: 5px;
  font-size: 0.85rem !important;
  color: #bcbcbc !important;
}

.primary-bg {
  background: $primary;
  border-radius: 15px;
  padding: 30px;
  text-align: left;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
  h2,h3,p {
    color: #fff!important;
  }
}

.black-bg {
  background: $five;
  border-radius: 15px;
  padding: 30px;  
  text-align: left;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
  h2,h3,p {
    color: #fff!important;
  }
}

.secondary-bg {
  background: $secondary;
  border-radius: 15px;
  padding: 30px;  
  text-align: left;
  color: #fff!important;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
}
.tertiary-bg {
  background: $tertiary;
  border-radius: 15px;
  padding: 30px;
  text-align: left;
  color: #fff;
  font-weight: 600;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
  h2,h3,p,a {
    color: #fff!important;
  }
}

.fourth-bg {
  background: $fourth;
  border-radius: 15px;
  padding: 30px;
  text-align: left;
  @media screen and (max-width:1499px) {
    padding: 15px;
  }
  h2,h3,p {
    color: #fff!important;
  }
}

.connexion {
  margin: auto;
  text-align: center;

  
  label {
      font-weight: 600;
      color: #000;
      font-size: 0.95rem;
  }
  .form-control {
      text-align: center;
      font-weight: 500;
      font-size: 0.95rem;
      &::placeholder {
          color: #AFAFAF!important;
          text-align: center;
      }
  }
  button {
      width: 100%;
      margin-top: 8px;
      font-size: 0.95rem;
  }
  .body {
    padding: 0px 100px;
  }
 
}


form {
  margin: auto;
  text-align: left;
  
  label {
      font-weight: 600;
      color: #000;
      font-size: 0.95rem;
  }
  .form-control, .form-select {
      
      text-align: left;
      font-weight: 500;
      font-size: 0.95rem;
      &::placeholder {
          color: #AFAFAF!important;
          text-align: left;
      }
  }
  button {
      margin-top: 8px;
      font-size: 0.95rem;
  }
  .body {
  }
 
}


.deco {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  @media screen and (max-width:1499px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .hello {
    text-decoration: underline;
    margin: 0px;
    margin-right: 20px;
    @media screen and (max-width:1499px) {
      margin-right: 0px;
    }
  }
}

table {
  border-collapse: separate!important; 
  border-spacing: 0px 15px;
  margin: 0px!important;
  width: 100%;
  overflow-x: scroll;
  i.bi.bi-check-square-fill {
    color: #14bc14;
  }
  i{
    cursor: pointer;
  }
  input, textarea {
    width: 100%;
    background: #ffffff;
    border: 0px;
    padding: 4px 10px;
    text-align: left;
    font-weight: 500;
    font-size: 0.9rem;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
  }
  td {
    border: 0px!important;
    font-size: 0.75rem!important;
    vertical-align: middle;
    @media screen and (max-width:1499px) {
      min-width: 200px;
      padding: 6px!important;
    }
  }
  tr:nth-child(odd) td {
    background-color: #F8F8F8; /* Couleur de fond pour les lignes impaires */
  }
  tr:nth-child(even) td {
    background-color: #F2F2F2; /* Couleur de fond pour les lignes impaires */
  }
  tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-left: 25px;
  }
  tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 25px;
  }
  thead {
    text-transform: uppercase;
    th {
      border: 0px!important;
      padding-bottom: 0px!important;
      font-size: 0.75rem;
    }
  }
}

.form-control, .form-select {
  background: #F5F4F4;
  border: 0px;
  
  text-align: left;
  font-weight: 500;
  font-size: 0.95rem;
  @media screen and (max-width:1499px) {
    padding: 6px 12px;
  }

}

.notFind {
  margin-top: 20px;
  font-size: 0.9rem;
}

.download {
  background: #30A29C !important;
  border-radius: 10px !important;
  color: #fff !important;
  border: 0px !important;
  padding: 6px 10px !important;
  font-weight: 600 !important;
  display: block;

  align-items: center !important;
  justify-content: flex-start !important;
  text-decoration: none;
}

table {
  overflow-x: scroll;
  width: 100%;

  @media screen and (max-width:1499px) {
    display: inline-block;
  }
}
.groupNumberCard, .productNumberCard {
  @media screen and (max-width:1499px) {
      text-align: center;
  }

  svg {
      @media screen and (max-width:1499px) {
          height: 75px;
      }
  }
}

@media screen and (max-width:1499px) {
  .filters input {
    width: 100%;
  }
}

div#swal2-validation-message {
  font-weight: 400;
}


.ql-snow .ql-editor h4 {
  font-size: 1.6em;
}

.ql-snow .ql-editor h3 {
  font-size: 2em;
}

.ql-snow .ql-editor h5 {
  font-size: 1.3em;
  font-weight: 700;
}

a.account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  text-decoration: none;
  color: $five;
  span {
    text-decoration: underline;
  }
}

.language-selector {
  display: flex;
  gap: 10px;
  margin-right: 20px;
  svg {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
}

.swal2-html-container {
  font-size: 1rem !important;
  line-height: 1.2rem !important;
}

</style>
