<template>
    <div class="userList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('group_list.title') }}</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('group_list.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            <table class="table" v-if="groupStore.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{ $t('group_list.columns.name') }}</th>
                        <th scope="col">{{ $t('group_list.columns.discount') }}</th>
                        <!-- Add more columns as needed -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="group in groupStore" :key="group.id">
                        <td>
                            <template v-if="groupEditing === group.id">
                                <input v-model="group.nom" @keyup.enter="updateGroup">
                            </template>
                            <template v-else>
                                {{ group.nom }}
                            </template>
                        </td>
                        <td>
                            <template v-if="groupEditing === group.id">
                                <input type="number" v-model="group.reduction" @keyup.enter="updateGroup" min="0" max="100">
                            </template>
                            <template v-else>
                                {{ group.reduction }}%
                            </template>
                        </td>
                        <td style="width: 1.5%;">
                            <i v-if="groupEditing === group.id" @click="updateGroup" class="bi bi-check-square-fill"></i>
                            <i v-else @click="startEditing(group.id)" class="bi bi-pen-fill"></i>
                        </td>
                        <td style="width: 1.5%;">
                            <i @click="deleteGroup(group.id)" class="bi bi-trash3-fill"></i>
                        </td>
                        <!-- Display more user data as needed -->
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('group_list.no_group_message') }} &#128533;</div>
            <button class="btn btn-primary" @click="loadGroups" v-if="hasMoreGroups">{{ $t('group_list.load_more_button') }}</button>
        </div>
    </div>
</template>

<script>

import { useGroupsStore } from '@/store/groups.store';

export default {
    data() {
        return {
            groups: [],
            message: '',
            groupEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        groupStore() {
            const groupsStore = useGroupsStore();
            return groupsStore.groups;
        },
     
        hasMoreGroups() {
            const groupsStore = useGroupsStore();
            return groupsStore.hasMoreGroups;
        }
    },
    mounted() {
        if (this.groupStore.length === 0) {
            this.loadGroups(); // Charger les groupes seulement s'il n'y en a pas déjà
        }
    },
    methods: {
        updateGroup() {

            const editedGroup = this.groupStore.find(group => group.id === this.groupEditing);
            if (!editedGroup) {
                console.error("Utilisateur non trouvé");
                return;
            }            

            const groupsStore = useGroupsStore();
            groupsStore.updateGroup(editedGroup);

            this.groupEditing = null; // Fin de l'édition

        },   

        loadGroups() {
            const groupsStore = useGroupsStore();
            groupsStore.loadGroups();
            
        },
     
        startEditing(groupId) {
            this.groupEditing = groupId; // Commencer l'édition de l'utilisateur
        },

        deleteGroup(groupDeleting) {

            const deletedGroup = this.groupStore.find(group => group.id === groupDeleting);

            const groupsStore = useGroupsStore();
            groupsStore.deleteGroup(deletedGroup);

            
        },
        handleSearch() {
            const groupsStore = useGroupsStore();
            groupsStore.params.query = this.searchQuery.trim();
            groupsStore.loadGroups(true); // Reset and load offers based on the search query
        },

    },
    beforeUnmount() {
        const groupsStore = useGroupsStore();
        groupsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>

</style>