<template>
    <div class="productList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('option_list.title') }}</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('option_list.filters.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            
            <table class="table" v-if="optionStore.length > 0">
                <thead>
                    <tr>
                        <th style="width: 50%;" scope="col">{{ $t('option_list.table.headers.name') }}</th>
                        <th style="width: 20%;">{{ $t('option_list.table.headers.category') }}</th>
                        <th style="width: 12%;" scope="col">{{ $t('option_list.table.headers.fixed_price') }}</th>
                        <th style="width: 25%;" scope="col">{{ $t('option_list.table.headers.linear_meter_price') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="option in optionStore" :key="option.id">
                        <td>
                            <template v-if="optionEditing === option.id">
                                <input v-model="option.nom" @keyup.enter="updateOption">
                            </template>
                            <template v-else>
                                {{ option.nom }}
                            </template>
                        </td>
                        <td>
                            {{ option.nom_type_option }}
                        </td>
                        <td>
                            <template v-if="optionEditing === option.id">
                                <input type="number" v-model="option.prix_fixe" @keyup.enter="updateOption">
                            </template>
                            <template v-else>
                                {{ formatNumber(option.prix_fixe) }} €
                            </template>
                        </td>
                        <td>
                            <template v-if="optionEditing === option.id">
                                <input type="number" v-model="option.prix_ml" @keyup.enter="updateOption">
                            </template>
                            <template v-else>
                                <span v-if="option.prix_ml">{{ option.prix_ml }} €</span>
                                <span v-else>{{ $t('option_list.table.no_defined') }}</span>
                            </template>
                        </td>
                        <td style="width: 1.5%;"><i v-if="optionEditing === option.id" @click="updateOption" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(option.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteOption(option.id)" class="bi bi-trash3-fill"></i></td>
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('option_list.table.no_options') }}</div>
            <button class="btn btn-primary" @click="loadOptions" v-if="hasMoreOptions">{{ $t('option_list.buttons.load_more') }}</button>
        </div>
    </div>
</template>

<script>

import { useOptionsStore } from '@/store/options.store';

export default {
    data() {
        return {
            options: [],
            message: '',
            optionEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        optionStore() {
            const optionsStore = useOptionsStore();
            return optionsStore.options;
        },
     
        hasMoreOptions() {
            const chaudieresStore = useOptionsStore();
            return chaudieresStore.hasMoreOptions;
        }
    },
    mounted() {
        if (this.optionStore.length === 0) {
            this.loadOptions(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {
        updateOption() {

            const editedOption = this.optionStore.find(option => option.id === this.optionEditing);
            if (!editedOption) {
                console.error("Option non trouvé");
                return;
            }            

            const optionsStore = useOptionsStore();
            optionsStore.updateOption(editedOption);

            this.optionEditing = null; // Fin de l'édition
            
        },   
        loadOptions() {
            const optionsStore = useOptionsStore();
            optionsStore.loadOptions();
        },


        
        startEditing(optionId) {
            this.optionEditing = optionId; // Commencer l'édition de l'utilisateur
        },

        deleteOption(optionDeleting) {

            const deletedOption = this.optionStore.find(option => option.id === optionDeleting);

            const optionsStore = useOptionsStore();
            optionsStore.deleteOption(deletedOption);

            
        },
        handleSearch() {
            const optionsStore = useOptionsStore();
            optionsStore.params.query = this.searchQuery;
            optionsStore.loadOptions(true); // Reset and load offers based on the search query
        },
        formatNumber(num) {
            if (num == null) {
                return '';
            }
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

    },
    beforeUnmount() {
        const optionsStore = useOptionsStore();
        optionsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    td {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
</style>