<script setup>
  import ConfigList from '@/components/configurateur/ConfigList.vue';
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="header">
        <h1>{{ $t('configurations_all.title') }}</h1>
      </div>
      <ConfigList/>
    </div>
  </div>
</template>

<script>
  export default {

  }
</script>