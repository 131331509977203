<template>
  <div class="askForm text-start mb-3 white-bg">
    <form class="askFormContent" @submit.prevent="submitForm">
      <div class="body">
        <!-- Champ caché pour l'email -->
        <input type="hidden" v-model="demande.email" />

        <input type="hidden" v-model="demande.prenom" />

        <!-- Nom du projet -->
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputNomProjet" class="form-label">{{ $t('other_requests.form.project_name') }}</label>
              <input type="text" v-model="demande.nomProjet" :placeholder="$t('other_requests.form.project_name_placeholder')" class="form-control" id="InputNomProjet" required>
            </div>
          </div>
        </div>

        <!-- Technologie -->
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputTechnologie" class="form-label">{{ $t('other_requests.form.technology') }}</label>
              <select v-model="demande.technologie" class="form-control" id="InputTechnologie" required>
                <option value="AE-AMINE">AE-AMINE</option>
                <option value="Liquéfaction de CO2">Liquéfaction de CO2</option>
                <option value="Autre demande">Autre demande</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Nature du projet -->
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-12">
              <label for="InputNatureProjet" class="form-label">{{ $t('other_requests.form.project_nature') }}</label>
              <textarea v-model="demande.natureProjet" class="form-control" id="InputNatureProjet" rows="5" :placeholder="$t('other_requests.form.project_nature_placeholder')" required></textarea>
            </div>
          </div>
        </div>

        <!-- Données d'entrée -->
        <div class="mb-3">
          <div class="row">
            <div class="col-lg-6">
              <label for="InputCompositionGaz" class="form-label">{{ $t('other_requests.form.gas_composition') }}</label>
              <textarea v-model="demande.compositionGaz" class="form-control" id="InputCompositionGaz" rows="3" :placeholder="$t('other_requests.form.gas_composition_placeholder')" required></textarea>
            </div>
            <div class="col-lg-6">
              <label for="InputObjectifPurification" class="form-label">{{ $t('other_requests.form.purification_goal') }}</label>
              <textarea v-model="demande.objectifPurification" class="form-control" id="InputObjectifPurification" rows="3" :placeholder="$t('other_requests.form.purification_goal_placeholder')" required></textarea>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">{{ $t('other_requests.form.submit_button') }}</button>
      </div>
    </form>
  </div>
</template>

<script>

import axios from '@/axiosConfig.js';
import Swal from 'sweetalert2';
import { useAuthStore } from '@/store/auth.store';

export default {
    data() {
        return {
            demande: {
              nomProjet: '',
              technologie: 'AE-AMINE',
              natureProjet: '',
              compositionGaz: '',
              objectifPurification: '',
              email: '',
              prenom: '',
              nom: ''
            },
            message: ''
        };
    },
    computed: {
        user() {
            const authStore = useAuthStore();
            return authStore.user;
        },
    },
    mounted() {
        this.demande.email = this.user.email;
        this.demande.prenom = this.user.prenom;
        this.demande.nom = this.user.nom;
    },
    methods: {
        submitForm() {
            axios.post('https://arolenergy-05673c117bbd.herokuapp.com/users/demande', this.demande)
                .then(response => {
                    console.log(response.data.message);
                    this.message = response.data.message;
                    this.showAlertPositive();
                })
                .catch(error => {
                    console.error(error.response?.data?.message || error.message);
                    this.message = error.response?.data?.message || "Une erreur s'est produite";
                    this.showAlertNegative();
                })
                .finally(() => {
                    // Réinitialiser le formulaire
                    this.demande = {
                      nomProjet: '',
                      technologie: '',
                      natureProjet: '',
                      compositionGaz: '',
                      objectifPurification: '',
                      email: this.user.email,
                      prenom: this.user.prenom,
                      nom: this.user.nom,
                    };
                });
        },
        showAlertPositive() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "success",
                title: this.message
            });
        },
        showAlertNegative() {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            Toast.fire({
                icon: "error",
                title: this.message
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .askForm {
        width: 55%;
        margin: auto;
        margin-top: 35px;
        @media screen and (max-width:992px) {
            width: 100%;
        }
    }
</style>