<template>
    <div class="productList text-start mb-3 white-bg">
        <div class="header">
            <h2>{{ $t('product_list.title') }}</h2>
            <div class="filters">
                <input class="form-control" type="text" v-model="searchQuery" :placeholder="$t('product_list.search_placeholder')" @input="handleSearch" />
            </div>
        </div>
        <div class="body">
            <table class="table" v-if="productStore.length > 0" style="display:inline-block;">
                <thead>
                    <tr>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.name') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.commercial_name') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.min_raw_biogas_flow') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.max_raw_biogas_flow') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.pre_treatment_capacity') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.activated_carbon_price') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.compressor_power') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.compressor_location') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.dimension') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.photo_without_boiler') }}</th>
                        <th style="min-width: 200px;" scope="col">{{ $t('product_list.table.photo_with_boiler') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in productStore" :key="product.id">
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.equip" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.equip }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.nom_commercial" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.nom_commercial }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input type="number" v-model="product.debit_mini_biogaz_brut_equip" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.debit_mini_biogaz_brut_equip }} Nm³/h
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input type="number" v-model="product.debit_max_biogaz_brut_equip" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.debit_max_biogaz_brut_equip }} Nm³/h
                            </template>
                        </td>

                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.pre_traitement_capacite" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.pre_traitement_capacite }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.prix_charbons_actifs" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.prix_charbons_actifs }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.puissance_compresseur" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.puissance_compresseur }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.localisation_compresseur" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.localisation_compresseur }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input v-model="product.dimension" @keyup.enter="updateProduct">
                            </template>
                            <template v-else>
                                {{ product.dimension }}
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input type="file" @change="handleFileChange($event, 'photo_sans_chaudiere', product)" />
                            </template>
                            <template v-else>
                                <!-- Utilise l'URL temporaire de l'image tant que le produit n'a pas été mis à jour -->
                                <img v-if="product.photo_sans_chaudiere" class="imgChaudiere" :src="product.photo_sans_chaudiere" alt="Photo sans chaudière" style="max-width: 100px;" />
                                <p class="no-image" v-else>Pas d'image</p>
                            </template>
                        </td>
                        <td>
                            <template v-if="productEditing === product.id">
                                <input type="file" @change="handleFileChange($event, 'photo_avec_chaudiere', product)" />
                            </template>
                            <template v-else>
                                <!-- Utilise l'URL temporaire de l'image tant que le produit n'a pas été mis à jour -->
                                <img v-if="product.photo_avec_chaudiere" class="imgChaudiere" :src="product.photo_avec_chaudiere" alt="Photo avec chaudière" style="max-width: 100px;" />
                                <p class="no-image" v-else>Pas d'image</p>
                            </template>
                        </td>
                        <td style="width: 1.5%;"><router-link :to="'/produits-equipements/' + product.equip"><i class="bi bi-plus-circle-fill"></i></router-link></td>
                        <td style="width: 1.5%;"><i v-if="productEditing === product.id" @click="updateProduct" class="bi bi-check-square-fill"></i><i v-else @click="startEditing(product.id)" class="bi bi-pen-fill"></i></td>
                        <td style="width: 1.5%;"><i @click="deleteProduct(product.id)" class="bi bi-trash3-fill"></i></td>
                        <!-- Display more product data as needed -->
                    </tr>
                </tbody>
            </table>
            <div class="notFind" v-else>{{ $t('product_list.table.no_products_available') }} &#128533;</div>
            <button class="btn btn-primary" @click="loadProducts" v-if="hasMoreProducts">{{ $t('product_list.table.load_more') }}</button>
        </div>
    </div>
</template>

<script>

import { useProductsStore } from '@/store/products.store';

export default {
    data() {
        return {
            products: [],
            message: '',
            productEditing: null,
            searchQuery: '',
        };
    },
    computed: {
        productStore() {
            const productsStore = useProductsStore();
            return productsStore.products;
        },
     
        hasMoreProducts() {
            const productsStore = useProductsStore();
            return productsStore.hasMoreProducts;
        }
    },
    mounted() {
        if (this.productStore.length === 0) {
            this.loadProducts(); // Charger les utilisateurs seulement s'il n'y en a pas déjà
        }
    },
    methods: {

        updateProduct() {
            const editedProduct = this.productStore.find(product => product.id === this.productEditing);
            if (!editedProduct) {
                console.error("Produit non trouvé");
                return;
            }

            const productsStore = useProductsStore();
            productsStore.updateProduct(editedProduct);

            this.productEditing = null; // Fin de l'édition
        },
        handleFileChange(event, field, product) {
            const file = event.target.files[0];
            if (file) {
                // Mise à jour du produit localement pour afficher l'aperçu immédiatement
                product[field] = URL.createObjectURL(file);

                // Ajouter l'image au formulaire à envoyer au backend
                product._fileToUpload = { field, file }; // Stocker le fichier pour l'envoi ultérieur
            }
        },
        loadProducts() {
            const productsStore = useProductsStore();
            productsStore.loadProducts();
        },


        
        startEditing(productId) {
            this.productEditing = productId; // Commencer l'édition de l'utilisateur
        },

        deleteProduct(productDeleting) {

            const deletedproduct = this.productStore.find(product => product.id === productDeleting);

            const productsStore = useProductsStore();
            productsStore.deleteProduct(deletedproduct);

            
        },
        handleSearch() {
            const productsStore = useProductsStore();
            productsStore.params.query = this.searchQuery;
            productsStore.loadProducts(true); // Reset and load offers based on the search query
        },


    },
    beforeUnmount() {
        const productsStore = useProductsStore();
        productsStore.resetState();
    }
}
</script>

<style lang="scss" scoped>
    td {
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .imgChaudiere {
        border-radius: 8px;
    }
    .no-image {
        font-size: .85rem !important;
        margin: 0;
    }
</style>